import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  Container,
  FormControlLabel,
  Button,
  Box,
  Typography,
  Grid,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { getTypes, getKinds, getCategories, getReaders, addItem, getItemData } from "../../api/axios";
import "./edit.css";
import { getUsername } from '../../context/AuthFunc';
import { useTranslation } from 'react-i18next';

const EditItem = () => {
  const { t } = useTranslation('global');
  const [openReadersModal, setOpenReadersModal] = useState(false);
  const [types, setTypes] = useState([]);
  const [kinds, setKinds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedTypeName, setSelectedTypeName] = useState('');
  const [readers, setReaders] = useState([]);
  const [formData, setFormData] = useState({
    category: '',
    category_id: '',
    daily_no: '',
    day_no: '',
    description: '',
    discount: '',
    entry_no: '',
    id: '',
    kind: '',
    log_card: '',
    price: '',
    readers: '',
    tax_group: '',
    templates: null,
    time_from: '',
    time_to: '',
    type: '',
    type_id: '',
    user_log: '',
    vat: '',
    kind_id: ''
  });
  const [kindDetails, setKindDetails] = useState({
    days: '',
    entries: '',
    dailyPasses: '',
    timeFrom: '',
    timeTo: '',
  });
  const { item_id } = useParams();

  useEffect(() => {
    fetchInitialData();
    fetchItemData(item_id);
  }, []);

  const fetchItemData = async (item_id) => {
    const id = new URLSearchParams();
    id.append('id', item_id);
    try {
      const response = await getItemData(id);
      console.log(response);
      setFormData({
        ...response,
      });

    }
    catch (error) {
      console.error(t("editItem.errorGettingItemData"));
      return false;
    }
  }

  const fetchInitialData = async () => {
    const typesData = await getTypes();
    const categoriesData = await getCategories();
    const kindsData = await getKinds();
    const readersData = await getReaders();

    setTypes(typesData);
    setCategories(categoriesData);
    setKinds(kindsData);
    setReaders(readersData);
  };

  const handleOpenReadersModal = () => setOpenReadersModal(true);
  const handleCloseReadersModal = () => setOpenReadersModal(false);
  const handleKindChange = (kindId) => {
    const selectedKind = kinds.find((kind) => kind.id === kindId);

    if (selectedKind) {
      // Pronađi povezani type
      const relatedType = types.find((type) => type.id === selectedKind.type);
      console.log(selectedKind);
      setFormData({
        ...formData,
        kind_id: kindId,
      });

      setKindDetails({
        days: selectedKind.days || '0',
        entries: selectedKind.entries || '0',
        dailyPasses: selectedKind.daily || '0',
        timeFrom: selectedKind.from || '00:00:00',
        timeTo: selectedKind.to || '23:59:59',
      });

      // Postavi ime povezane vrste
      setSelectedTypeName(relatedType ? relatedType.name : '');
    }
  };


  const handleCheckboxChange = (readerId) => {
    let updatedReaders = [...formData.readers];
    if (readerId === 'all') {
      updatedReaders = readers.map((r) => r.id); // Select all readers
    } else {
      if (updatedReaders.includes(readerId)) {
        updatedReaders = updatedReaders.filter((id) => id !== readerId);
      } else {
        updatedReaders.push(readerId);
      }
    }
    setFormData({ ...formData, readers: updatedReaders });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = getUsername();
    const params = {
      price: formData.price,
      vat: formData.vat,
      type_id: formData.type_id,
      kind_id: formData.kind_id,
      category_id: formData.category_id,
      disc: formData.disc,
      user: username,
      readers: formData.readers,
      desc: formData.desc,
    };

    try {
      const response = await addItem(params);
      console.log('Item added successfully:', response.data);
    } catch (error) {
      console.error('Failed to add item:', error);
    }
  };

  return (
    <Container className="container-item" sx={{ p: 3, mt: 4 }} maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        {t("editItem.editItem")}
      </Typography>
      <form onSubmit={handleSubmit} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Grid container spacing={2}>
          {/* Vrsta */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>{t("editItem.kind")}</InputLabel>
              <Select
                value={formData.kind_id}
                onChange={(e) => handleKindChange(e.target.value)}
              >
                {kinds.map((kind) => (
                  <MenuItem key={kind.id} value={kind.id}>
                    {kind.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Tip */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("editItem.type")}
              value={selectedTypeName}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label={t("editItem.numberOfDays")}
              value={kindDetails.days}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label={t("editItem.numberOfEntries")}
              value={kindDetails.entries}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label={t("editItem.dailyNumberOfEntries")}
              value={kindDetails.dailyPasses}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("editItem.from")}
              value={kindDetails.timeFrom}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t("editItem.until")}
              value={kindDetails.timeTo}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          {/* Kategorija */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>{t("editItem.category")}</InputLabel>
              <Select
                value={formData.category_id}
                onChange={(e) => setFormData({ ...formData, category_id: e.target.value })}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Cena */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={t("editItem.price")}
              type="number"
              value={formData.price}
              onChange={(e) => setFormData({ ...formData, price: e.target.value })}
            />
          </Grid>

          {/* PDV */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={t("editItem.VAT")}
              type="number"
              value={formData.vat}
              onChange={(e) => setFormData({ ...formData, vat: e.target.value })}
            />
          </Grid>

          {/* Popust */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("editItem.discount")}
              type="number"
              value={formData.disc}
              onChange={(e) => setFormData({ ...formData, disc: e.target.value })}
            />
          </Grid>

          {/* Opis */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label={t("editItem.description")}
              multiline
              rows={3}
              value={formData.desc}
              onChange={(e) => setFormData({ ...formData, desc: e.target.value })}
            />
          </Grid>

          {/* Čitači */}
          <Grid item xs={12}>
            <Button variant="outlined" onClick={handleOpenReadersModal}>
              {t("editItem.selectReaders")}
            </Button>
          </Grid>

          <Dialog open={openReadersModal} onClose={handleCloseReadersModal} fullWidth maxWidth="sm">
            <DialogTitle>{t("editItem.readers")}</DialogTitle>
            <DialogContent>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleCheckboxChange('all')}
                    checked={formData.readers.length === readers.length}
                  />
                }
                label={t("editItem.all")}
              />
              <Grid container spacing={1}>
                {readers.map((reader) => (
                  <Grid item xs={12} sm={6} key={reader.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.readers.includes(reader.id)}
                          onChange={() => handleCheckboxChange(reader.id)}
                        />
                      }
                      label={reader.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseReadersModal} color="primary" variant="contained">
                {t("editItem.confirm")}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dugme */}
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained" color="primary" sx={{ width: "50%" }}>
              {t("editItem.edit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default EditItem