import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Pagination,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import {closePrinterSession, getPriceList, openPrinterSession, sendTicketData} from "../../api/axios";

const ITEMS_PER_PAGE = 16; // Broj artikala po stranici

const Shopping = () => {
  const { t } = useTranslation("global");
  const [items, setItems] = useState([]); // Lista artikala iz API-ja
  const [cart, setCart] = useState([]); // Lista odabranih artikala
  const [currentPage, setCurrentPage] = useState(1); // Trenutna stranica
  const [itemsPerPage, setItemsPerPage] = useState(16); // Podrazumevana vrednost

  // Funkcija za izračunavanje elemenata po stranici
  const calculateItemsPerPage = () => {
    const itemHeight = 120; // Približna visina jednog elementa u pikselima
    const availableHeight = window.innerHeight - 300; // Oduzimamo prostor za header, footer, itd.
    return Math.floor(availableHeight / itemHeight) * 4; // 4 elementa u redu
  };

  useEffect(() => {
    // Postavljanje broja elemenata pri inicijalizaciji
    setItemsPerPage(calculateItemsPerPage());

    // Event listener za promenu veličine ekrana
    const handleResize = () => {
      setItemsPerPage(calculateItemsPerPage());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const getPaginatedItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return items.slice(startIndex, endIndex);
  };


  // Učitavanje liste artikala iz API-ja
  useEffect(() => {
    const fetchPriceList = async () => {
      try {
        const response = await getPriceList(); // Poziv API-ja
        if (response && Array.isArray(response)) {
          setItems(response);
        } else {
          console.error("Unexpected API response:", response);
          setItems([]);
        }
      } catch (error) {
        console.error("Failed to fetch price list:", error);
        setItems([]); // Ako API poziv ne uspe, postavi praznu listu
      }
    };
    fetchPriceList();
  }, []);

  // Dodavanje artikla u korpu
  const addToCart = (item) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((cartItem) => cartItem.id === item.id);
      if (existingItem) {
        return prevCart.map((cartItem) =>
            cartItem.id === item.id
                ? { ...cartItem, quantity: cartItem.quantity + 1 }
                : cartItem
        );
      }
      return [...prevCart, { ...item, quantity: 1 }];
    });
  };

  // Smanjivanje količine ili uklanjanje artikla iz korpe
  const removeFromCart = (itemId) => {
    setCart((prevCart) =>
        prevCart
            .map((cartItem) =>
                cartItem.id === itemId
                    ? { ...cartItem, quantity: cartItem.quantity - 1 }
                    : cartItem
            )
            .filter((cartItem) => cartItem.quantity > 0)
    );
  };

  // Izračunavanje ukupne cene
  const calculateTotal = () => {
    return cart
        .reduce((total, item) => total + item.quantity * item.price, 0)
        .toFixed(2);
  };

  // Print
  const handlePrint = async () => {
    if (cart.length === 0) {
      return;
    }

    try {
      console.log("🔵 Otvaranje sesije štampanja...");
      await openPrinterSession();

      console.log("🟢 Slanje podataka za svaku kartu...");
      for (const item of cart) {
        console.log(item);

        if (item.type.split(" ")[1] === "KARTICA" || item.type.split(" ")[1] === "KARTA") {
          if(item.quantity > 1){
            for (let i = 0; i < item.quantity; i++) {

              const ticketData = {
                // Ime kompanije koja prodaje karte
                id: item.id,
                category: item.category,
                kind: item.kind,
                startDate: new Date(),
                endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                price: item.price,
                // logo
                // Digital Access Logic
                text: "Hvala" //dodati tekst pozdrava i uljudnosti

              };

              await sendTicketData(ticketData);
            }
          }else {
            const ticketData = {
              // Ime kompanije koja prodaje karte
              id: item.id,
              category: item.category,
              kind: item.kind,
              startDate: new Date(),
              endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
              price: item.price,
              // logo
              // Digital Access Logic
              text: "Hvala" //dodati tekst pozdrava i uljudnosti

            };
            await sendTicketData(ticketData);
          }
        }
      }

      console.log("🟣 Generisanje PDF-a i štampa...");
      await closePrinterSession();

      setCart([]); // Resetujemo korpu nakon štampanja
    } catch (error) {
      console.error(error);
    }
  };



  return (
      <Container maxWidth={false} sx={{ padding: "16px" }}>
        <Typography variant="h4" gutterBottom mt={4}>
          {t("shopping.title")}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h6">
              {t("shopping.availableItems")}
            </Typography>

            {/* Legenda za boje */}
            <Box display="flex" alignItems="center" gap={2} mt={2} mb={2}>
              <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#f8af46",
                    border: "1px solid gray",
                    borderRadius: "4px",
                  }}
              />
              <Typography variant="body2">
                {t("shopping.paper")}
              </Typography>
              <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#ec7732",
                    border: "1px solid gray",
                    borderRadius: "4px",
                  }}
              />
              <Typography variant="body2">
                {t("shopping.plastic")}
              </Typography>
              <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#6ec8af",
                    border: "1px solid gray",
                    borderRadius: "4px",
                  }}
              />
              <Typography variant="body2">
                {t("shopping.other")}
              </Typography>
            </Box>

            <Grid container spacing={2} mt={1}>
              {getPaginatedItems().length === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      {t("shopping.noItems")}
                    </Typography>
                  </Grid>
              ) : (
                  getPaginatedItems().map((item) => (
                      <Grid
                          item
                          xs={12}
                          sm={3}
                          key={item.id}
                          onClick={() => addToCart(item)}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                                item.kind === "PAPIRNA"
                                    ? "#f8af46"
                                    : item.kind === "PLASTICNA"
                                        ? "#ec7732"
                                        : "#6ec8af",
                            "&:hover": {
                              backgroundColor:
                                  item.kind === "PAPIRNA"
                                      ? "#f4a03c"
                                      : item.kind === "PLASTICNA"
                                          ? "#e5631f"
                                          : "#5eb59c",
                              borderRadius: "8px",
                            },
                            padding: "8px",
                            borderRadius: "8px",
                            color: "black",
                            border: "1px solid gray",
                          }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          {item.type}
                        </Typography>
                        <Typography variant="body2">
                          {`${t("shopping.category")}: ${item.category || "-"}`}
                        </Typography>
                        <Typography variant="body2">
                          {`${t("shopping.price")}: ${item.price} RSD`}
                        </Typography>
                      </Grid>
                  ))
              )}
            </Grid>
            <Box mt={2} display="flex" justifyContent="center">
              <Pagination
                  count={Math.ceil(items.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
              />
            </Box>
          </Grid>

          {/* Desna strana - Korpa */}
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Razmak između elemenata
            padding: "16px", // Dodaj padding za lep izgled
          }}>
            <Box>
              <Typography variant="h6">{t("shopping.cart")}</Typography>
              <List sx={{
                height: "50vh",
                overflow: "auto", // Dodaje skrolbar kada je sadržaj prevelik
              }}>
                {cart.map((item) => (
                    <ListItem key={item.id} divider>
                      <ListItemText
                          primary={item.type}
                          secondary={
                            <>
                              {`${t("shopping.price")}: ${item.price} RSD`}
                              <br />
                            </>
                          }
                      />
                      <Box display="flex" alignItems="center">
                        <IconButton
                            size="small"
                            onClick={() => removeFromCart(item.id)}
                        >
                          <Remove />
                        </IconButton>
                        <Typography variant="body1" mx={1}>
                          {item.quantity}
                        </Typography>
                        <IconButton
                            size="small"
                            onClick={() => addToCart(item)}
                        >
                          <Add />
                        </IconButton>
                      </Box>
                    </ListItem>
                ))}
              </List>
            </Box>
            <Box mt={2}>
              <Typography variant="h6">
                {t("shopping.total")}: {calculateTotal()} RSD
              </Typography>
              <Button
                  variant="contained"
                  color="primary"
                  disabled={cart.length === 0}
                  fullWidth
                  onClick={() => handlePrint()}
              >
                {t("shopping.checkout")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

  );
};

export default Shopping;
