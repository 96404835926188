import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { editReader, getReaderData, getReaders } from '../../api/axios';
import "./edit.css";
import { useTranslation } from 'react-i18next';
import { Alert,TextField, Button, Checkbox, Container, Dialog, DialogTitle, DialogContent, DialogActions,FormControl, FormControlLabel, InputLabel, Select, MenuItem, Typography, Grid, Box } from '@mui/material';

const EditReader = () => {
  const { t } = useTranslation('global');
  const { id } = useParams();
  const navigate = useNavigate();
  const [readers, setReaders] = useState([{}]);
  const [reader, setReader] = useState({ id: id, name: '', serial_num: '', cooldown: 0, access_type: '', cooldown_group: ''});
  const [errMsg, setErrMsg] = useState('');
  const [succ, setSucc] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReaders, setSelectedReaders] = useState([]);

  const fetchReaders = async () =>{
    const response = await getReaders();
    setReaders(response.filter(reader => reader.id !== id));
    
  }
  const fetchReaderData = async () => {
    const response = await getReaderData(id);
    const readerData = {
      ...response,
      active: response.active == 1
    };
    setSelectedReaders(readerData.cooldown_group.split(',').filter(Boolean));
    setReader(readerData);
  };
  const handleChange = (event) => {
    setReader({...reader, access_type:event.target.value});
  };
  const handleReaderChange = (e) => {
    const value = e.target.value;
    
    setSelectedReaders((prev) => {
      const updatedReaders = prev.includes(value)
        ? prev.filter((reader) => reader !== value)
        : [...prev, value];
      
      // Update the reader's cooldown_group with the new state
      setReader({...reader, cooldown_group: updatedReaders.join(",")});
      
      return updatedReaders;
    });
  };    

  useEffect(() => {
    fetchReaders();
    fetchReaderData();
  }, [id]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (reader.name === '' || reader.serial_num === '' || reader.cooldown === '') {
      setErrMsg(t("editReader.allFieldsAreRequired"));
      return;
    }
    setReader({...reader, cooldown_group: (selectedReaders.filter(Boolean).join(","))});
    console.log(reader);
    const response = await editReader(reader);
    if (!response.success) {
      setErrMsg(t("editReader.errorDuringEdit"))
    }
    else {
      setSucc(t("editReader.Successful"));
      setErrMsg('');
      navigate('/readers');
    }
  };

  return (
    <Container maxWidth="sm" style={{ alignItems: "center", justifyContent: "center" }}>
      <Box
        sx={{
          mt: 4,
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: '#f9f9f9',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          {t("editReader.editReader")}
        </Typography>

        <form onSubmit={handleSave} className="form">
          
          <Box item xs={12}>
            <TextField
              fullWidth
              id="name"
              label={t("editReader.name")}
              value={reader.name}
              onChange={(e) => setReader({ ...reader, name: e.target.value })}
              variant="outlined"
            />
          </Box>

          <Box item xs={12}>
            <TextField
              fullWidth
              id="serial_num"
              label={t("editReader.serialNumber")}
              value={reader.serial_num}
              onChange={(e) => setReader({ ...reader, serial_num: e.target.value })}
              variant="outlined"
            />
          </Box>
          <Box item xs={12}>
            <TextField
              fullWidth
              id="cooldown"
              label={t("editReader.cooldown")}
              value={reader.cooldown}
              onChange={(e) => setReader({ ...reader, cooldown: e.target.value })}
              variant="outlined"
            />
          </Box>
          <FormControl fullWidth>
          <InputLabel id="access-type">{t("editReader.accessType")}</InputLabel>
            <Select
              labelId="access-type"
              id="access-type"
              value={reader.access_type}
              label={t("editReader.accessType")}
              onChange={handleChange}
            >
              <MenuItem value={"ulaz"}>{t("editReader.entrance")}</MenuItem>
              <MenuItem value={"izlaz"}>{t("editReader.exit")}</MenuItem>
            </Select>
        </FormControl>
          <Box>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setReader({ ...reader, active: (e.target.checked) ? 1 : 0 })}
                checked={reader.active == 1 ? true : false}
              />
            }
            label={t("editReader.active")}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setReader({ ...reader, anti_passback: (e.target.checked) ? 1 : 0 })}
                checked={reader.anti_passback == 1 ? true : false}
              />
            }
            label={t("editReader.anti_passback")}
          />
          
          </Box>
          <Grid item xs={12}>
                          <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
                              {t("editReader.cooldownGroup")}
                          </Button>
                      </Grid>
          <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} fullWidth maxWidth="sm">
            <DialogTitle>{t("editReader.selectReader")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {readers
                    .filter(reader => reader.id !== id)
                    .map((reader) => (
                        <Grid item xs={12} sm={6} key={reader.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedReaders.includes(reader.id)}
                                        onChange={handleReaderChange}
                                        value={reader.id}
                                    />
                                }
                                label={reader.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsModalOpen(false)} variant="contained" color="primary">
                    {t("editReader.ok")}
                </Button>
            </DialogActions>
        </Dialog>

          {errMsg && (
            <Box item xs={12}>
              <Alert severity="error" onClose={() => setErrMsg('')}>
                {errMsg}
              </Alert>
            </Box>
          )}
          {succ && (
            <Box item xs={12}>
              <Alert severity="success" onClose={() => setSucc('')}>
                {succ}
              </Alert>
            </Box>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                {t("editReader.save")}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                type="button"
                variant="contained"
                color="error"
                onClick={() => { navigate('/readers') }}
              >
                {t("editReader.cancel")}
              </Button>
            </Grid>

          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default EditReader;
