import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, Box } from "@mui/material";
import { useTranslation } from 'react-i18next';

const UserManuals = ({ open, handleClose, page }) => {
    const { t } = useTranslation('global');
    const [path, setPath] = useState('');
    const [html, setHtml] = useState('');
    const cleanPath = (page) => {
        let uncleanPath = page;
        if (uncleanPath.includes("/edit/")) {
            uncleanPath = "/" + ((((uncleanPath).split("/")).filter(Boolean)).splice(0, 2).join("/"));
        }
        if (uncleanPath.includes("/hik/")) {
            uncleanPath = "/" + ((((uncleanPath).split("/")).filter(Boolean)).splice(0,2).join("/"));
        }
        if (uncleanPath.includes("?")) {
            uncleanPath = uncleanPath.split("?")[0];
        }
        if (uncleanPath == "/") {
            uncleanPath = "/home";
        }
        setPath(uncleanPath);
    }
    useEffect(() => {
        cleanPath(page);
        let translate = localStorage.getItem('language') || 'sr'
        let userManual = "";
        if(translate == 'sr'){
            userManual = "/user_manual_rs.html";
        }
        else{
            userManual = "/user_manual_en.html";
        }
        fetch(userManual)
            .then((res) => res.text())
            .then((data) => {
                setHtml(data);
                document.getElementById(path)?.scrollIntoView();
            });
    }, [open, page])

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogContent>
                <Box dangerouslySetInnerHTML={{ __html: html }} />
            </DialogContent>
            <Button onClick={handleClose}>Close</Button>
        </Dialog>
    );
};

export default UserManuals;