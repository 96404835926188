import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText, Box, Typography, Container, CircularProgress, Alert, TextField, Button } from "@mui/material";
import { getTemplateGroups, addTemplateGroup } from "../../api/axios";
import { useTranslation } from "react-i18next";

const TemplateGroups = () => {
    const { t } = useTranslation("global");
    const [templateGroups, setTemplateGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newGroupName, setNewGroupName] = useState("");
    const [adding, setAdding] = useState(false);
    const [addError, setAddError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const groups = await getTemplateGroups();
                setTemplateGroups(groups);
            } catch (err) {
                setError(t("templateGroups.errorFetching"));
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [t]);

    const handleAddGroup = async () => {
        if (!newGroupName.trim()) return;
        setAdding(true);
        setAddError(null);
    
        try {
            const params = new URLSearchParams();
            params.append("name", newGroupName);
            await addTemplateGroup(params); // API poziv
    
            window.location.reload(); // Osvežavanje stranice nakon uspešnog dodavanja
        } catch (err) {
            setAddError(t("templateGroups.errorAdding"));
        } finally {
            setAdding(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    mt: 4,
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "#f9f9f9",
                }}
            >
                <Typography variant="h4" align="center" gutterBottom>
                    {t("templateGroups.templateGroups")}
                </Typography>

                {loading && <CircularProgress sx={{ display: "block", mx: "auto", mt: 2 }} />}
                {error && <Alert severity="error">{error}</Alert>}

                {!loading && !error && (
                    <List>
                        {templateGroups.map((group, index) => (
                            <ListItem key={index} divider>
                                <ListItemText primary={group.name}/>
                            </ListItem>
                        ))}

                        {/* Input za dodavanje nove grupe */}
                        <ListItem>
                            <TextField
                                fullWidth
                                label={t("templateGroups.newGroup")}
                                variant="outlined"
                                value={newGroupName}
                                onChange={(e) => setNewGroupName(e.target.value)}
                            />
                            <Button 
                                variant="contained" 
                                color="primary" 
                                sx={{ ml: 2, whiteSpace: "nowrap" }} 
                                onClick={handleAddGroup} 
                                disabled={adding}
                            >
                                {t("templateGroups.addButton")}
                            </Button>
                        </ListItem>

                        {addError && <Alert severity="error" sx={{ mt: 2 }}>{addError}</Alert>}
                    </List>
                )}
            </Box>
        </Container>
    );
};

export default TemplateGroups;
