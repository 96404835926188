import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconButton, Menu, MenuItem, Grid, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '../Logout/Logout';
import { hasPermission } from '../../context/AuthFunc';
import { PERMISSIONS } from '../../constants/const';
import { Download as DownloadIcon } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyIcon from '@mui/icons-material/Key';

const SettingsMenu = ({ isLoggedIn, setIsLoggedIn }) => {

  const { t } = useTranslation('global');
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <IconButton  sx={{ color: "white" }} onClick={handleClick} className="settingsMenu">
        <AccountCircleIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { hasPermission(PERMISSIONS.EDIT_SETTINGS) &&
            <MenuItem onClick={() => { navigate('/settings') }}>
                
                <Grid container spacing={2}>
                    <Grid xs={6} item sx={{ display: "flex", justifyContent: "flex-start" }}>{t('navbar.Settings')}</Grid>
                    <Grid xs={6} item sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <SettingsIcon />
                    </Grid>
                </Grid>
            </MenuItem>
        }
        { hasPermission(PERMISSIONS.VIEW_UPDATE_DATABASE) &&
            <MenuItem onClick={() => { navigate('/update-database') }}>
                <Grid container spacing={2}>
                    <Grid xs={6} item sx={{ display: "flex", justifyContent: "flex-start" }}>{t('navbar.updateDatabase')}</Grid>
                    <Grid xs={6} item sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <DownloadIcon />
                    </Grid>
                </Grid>
            </MenuItem>
        }
        <Divider variant="middle" component="li" />
        <MenuItem onClick={() => { navigate('/change-password') }}>
                <Grid container spacing={2}>
                    <Grid xs={6} item sx={{ display: "flex", justifyContent: "flex-start" }}>{t('navbar.changePassword')}</Grid>
                    <Grid xs={6} item sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <KeyIcon />
                    </Grid>
                </Grid>
              
        </MenuItem>
        <Logout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </Menu>
    </div>
  );
};

export default SettingsMenu;

/*

<>
              <IconButton sx={{ color: "white" }} onClick={() => setOpen(true)}>
                <HelpIcon />
              </IconButton>

              
            </>
            { hasPermission(PERMISSIONS.EDIT_MAIL_SETTINGS) &&
              <IconButton sx={{ color: "white" }} href="/mail-settings">
                <SettingsIcon />
              </IconButton>
            }
            
            
            
            
            
            
*/
