import React, { useEffect, useState } from 'react';
import './monitoring.css'
import {
  Box,
  Typography,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Paper
} from '@mui/material';
import { getMonitoring } from '../../api/axios';
import { useTranslation } from 'react-i18next';

const Monitoring = () => {
  const { t } = useTranslation('global');

  const [events, setEvents] = useState([]);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);

  const fetchData = async () => {
    try {
      const date = new Date();

      // Kreiranje današnjeg datuma
      const year = date.getFullYear().toString().slice(-2);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const formattedEndDate = `20${year}-${month}-${day}`;

      // Kreiranje jučerašnjeg datuma
      date.setDate(date.getDate() - 1); // Oduzima 1 dan

      const prevYear = date.getFullYear().toString().slice(-2);
      const prevMonth = String(date.getMonth() + 1).padStart(2, '0');
      const prevDay = String(date.getDate()).padStart(2, '0');

      const formattedStartDate = `20${prevYear}-${prevMonth}-${prevDay}`;

      // Pozivanje API funkcije sa datumima
      const response = await getMonitoring(formattedStartDate, formattedEndDate);

      setEvents(response.slice(0, 15)); // Prikazujemo samo prvih 15 rezultata
    } catch (error) {
      console.error(t("monitoring.errorFetchingEvents"), error);
    }
  };

  useEffect(() => {
    // Prvo učitavanje podataka
    fetchData();

    // Ažuriranje podataka svakih sekundu
    const interval = setInterval(() => {
      fetchData();
      setTimestamp(Date.now()); // Ažuriramo timestamp kako bi komponenta ponovo renderovala
    }, 1000);

    // Očisti interval pri demontiranju komponente
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Filtriraj događaje na osnovu termina za pretragu
    if (Array.isArray(events)) {
      setFilteredEvents(
        events.filter(event =>
          Object.values(event).some(val =>
            val.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      );
    }
  }, [searchTerm, events]);

  return (
    <Box sx={{ padding: 3, margin: '0 auto' }}>
      <Box sx={{ padding: 3, maxWidth: 1200, margin: '0 auto' }}>
        <Typography variant="h4" gutterBottom>
          {t("monitoring.monitor")}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {t("monitoring.lastUpdate")} {new Date(timestamp).toLocaleTimeString()}
        </Typography>
        <TextField
          label={t("monitoring.search")}
          variant="outlined"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          fullWidth
          sx={{ marginBottom: 3 }}
        />
      </Box>
      {filteredEvents.length > 0 ? (
        <Paper elevation={3} sx={{ overflow: 'auto' }}>
          <Table sx={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell className='monitoring-padding' sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Tooltip title={t("monitoring.ticket")} arrow>
                    <span>{t("monitoring.ticket")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding' sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Tooltip title={t("monitoring.registration")} arrow>
                    <span>{t("monitoring.registration")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding' >
                  <Tooltip title={t("monitoring.dateAndTime")} arrow>
                    <span>{t("monitoring.dateAndTime")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <Tooltip title={t("monitoring.barrier")} arrow>
                    <span>{t("monitoring.barrier")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding'>
                  <Tooltip title={t("monitoring.result")} arrow>
                    <span>{t("monitoring.result")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding'>
                  <Tooltip title={t("monitoring.name")} arrow>
                    <span>{t("monitoring.name")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding'>
                  <Tooltip title={t("monitoring.lastName")} arrow>
                    <span>{t("monitoring.lastName")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <Tooltip title={t("monitoring.passesRemaining")} arrow>
                    <span>{t("monitoring.passesRemaining")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <Tooltip title={t("monitoring.daysRemaining")} arrow>
                    <span>{t("monitoring.daysRemaining")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding'>
                  <Tooltip title={t("monitoring.remainingToday")} arrow>
                    <span>{t("monitoring.remainingToday")}</span>
                  </Tooltip>
                </TableCell>
                <TableCell className='monitoring-padding'>
                  <Tooltip title={t("monitoring.expireDate")} arrow>
                    <span>{t("monitoring.expireDate")}</span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEvents.map((event, index) => (
                <TableRow
                  key={`${event.serial_num}-${index}`}
                  sx={{
                    backgroundColor:
                      event.reject === 'Success' ? 'rgba(76, 175, 80, 0.2)' : 'rgba(244, 67, 54, 0.2)',
                  }}
                >
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.serial_num} arrow>
                      <span>{event.serial_num}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.registration} arrow>
                      <span>{event.registration}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.datetime} arrow>
                      <span>{event.datetime}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.mac_addr} arrow>
                      <span>{event.mac_addr}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.reject} arrow>
                      <span>{event.reject}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.name} arrow>
                      <span>{event.name}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.surname} arrow>
                      <span>{event.surname}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.remaining_passes} arrow>
                      <span>{event.remaining_passes}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.remaining_days} arrow>
                      <span>{event.remaining_days}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.remaining_daily} arrow>
                      <span>{event.remaining_daily}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className='monitoring-padding' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Tooltip title={event.exceed_date} arrow>
                      <span>{event.exceed_date}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        <Typography variant="body1">{t("monitoring.empty")}</Typography>
      )}
    </Box>
  );
};

export default Monitoring;

