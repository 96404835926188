import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Table, TableBody, TableCell, TableRow, TextField, Button } from "@mui/material";
import { getImage, getReaders } from "../../api/axios";
import { useTranslation } from "react-i18next";

const HIK = () => {
    const { t } = useTranslation("global");
    const [readers, setReaders] = useState([]);
    const [count, setCount] = useState(10);

    const [imagesByReader, setImagesByReader] = useState({});
    const [selectedImageIndex, setSelectedImageIndex] = useState({});
    const [errMsg, setErrMsg] = useState("");
    const applyChanges = () =>{
        for (let key in selectedImageIndex) {
            selectedImageIndex[key] = 0;
        }
        fetchReadersAndImages();
    }
    const fetchReadersAndImages = async () => {
        try {
            const readerList = await getReaders();
            if (!readerList || !Array.isArray(readerList)) {
                throw new Error("Invalid readers response");
            }
            setReaders(readerList);

            const imagesMap = {};
            for (const reader of readerList) {
                try {
                    const response = await getImage(reader.serial_num, count);
                    imagesMap[reader.serial_num] = response && Array.isArray(response) ? response : [];
                    console.log(imagesMap[reader.serial_num]);
                } catch (imageError) {
                    console.error(`Error fetching images for reader ${reader.serial_num}:`, imageError);
                    imagesMap[reader.serial_num] = [];
                }
            }

            setImagesByReader(imagesMap);
            setErrMsg("");
        } catch (error) {
            console.error("Error fetching readers and images:", error);
            setErrMsg(t("hik.errorFetchingUpdates"));
        }
    };

    useEffect(() => {
        fetchReadersAndImages();
    }, []);

    return (
        <Box sx={{ width: "100%", height: "calc(100vh - 64px)", p: 2 }}>
            {errMsg && <Typography color="error">{errMsg}</Typography>}
            <Grid container spacing={2}>
                <Grid item xs={3}>
                <TextField
                                    type="text"
                                    label={t("hik.count")}
                                    fullWidth
                                    value={count}
                                    onChange={(e) => setCount(e.target.value)}
                                />

                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" color="primary" sx={{ width: "50%", height: "100%" }} onClick={()=>applyChanges()}>{t("hik.apply")}</Button>

                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ height: "100%" }}>
                {readers.map((reader, index) => (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        key={reader.serial_num}
                        sx={{ height: { xs: 'auto', lg: 'calc(50vh - 64px)' } }}
                    >   <Typography variant="h6" align="center">{t("hik.reader")}: {reader.serial_num}</Typography>
                        <Grid container spacing={2}  sx={{ height: "100%" }}>
                            
                            <Grid item xs={12} sm={12} md={6} lg={6}
                                sx={{ position: "relative", height: { xs: '40vh'}, overflow: "hidden" }}>
                                {imagesByReader[reader.serial_num] && imagesByReader[reader.serial_num].length > 0 ?
                                (
                                    <Box key={index} sx={{ position: "relative", width: "100%", height: "100%" }}>
                                        <Box
                                            component="img"
                                            src={imagesByReader[reader.serial_num][selectedImageIndex[reader.serial_num] || 0].image ? 
                                                `data:image/jpg;base64,${imagesByReader[reader.serial_num][selectedImageIndex[reader.serial_num] || 0].image}` : ""}
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                            alt={`Image from ${imagesByReader[reader.serial_num][selectedImageIndex[reader.serial_num] || 0].reader}`}
                                        />
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                color: "white",
                                            }}
                                        >
                                            <Typography variant="body1" sx={{ backgroundColor: "rgba(0, 0, 0, 0.6)", p: 0.5}}>
                                                {imagesByReader[reader.serial_num][selectedImageIndex[reader.serial_num] || 0].id}
                                            </Typography>
                                            <Typography variant="caption" sx={{ backgroundColor: "rgba(0, 0, 0, 0.6)", p: 0.5}}>
                                                {imagesByReader[reader.serial_num][selectedImageIndex[reader.serial_num] || 0].modified_date}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Typography color="white" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                        No Image Available
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ position: "relative", height: { xs: '40vh', sm: '40vh' }, width: "100%", overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                                {imagesByReader[reader.serial_num] && imagesByReader[reader.serial_num].length > 0 ? (
                                    <Box sx={{ flex: 1, overflow: 'auto' }}>
                                    <Table sx={{ width: "100%", tableLayout: 'fixed' }}>
                                        <TableBody>
                                            {imagesByReader[reader.serial_num].map((image, index) => (
                                                <TableRow
                                                    hover
                                                    onClick={() => setSelectedImageIndex(prev => ({
                                                        ...prev,
                                                        [reader.serial_num]: index
                                                    }))}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        backgroundColor: selectedImageIndex[reader.serial_num] === index ? '#f5f5f5' : 'inherit'
                                                    }}
                                                >
                                                    <TableCell align="center">{image.id}</TableCell>
                                                    <TableCell align="center">{image.modified_date}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    </Box>
                                ) : (
                                    <Typography color="white" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>No data available</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default HIK;
