import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Container, Alert, Grid, Dialog, DialogContent, FormControlLabel, Checkbox, DialogActions, DialogTitle } from '@mui/material';
import DisplayForm from '../Table/DisplayForm'
import { getInfoPerTID} from '../../api/axios';
import { useTranslation } from 'react-i18next';

const PerTicketNumber = () => {
    const { t } = useTranslation('global');

    const [ticket, setTicket] = useState({create_time: "", exceed_date: "", name:"", surname:"", phone:"", h_to: "", h_from:"", remaining_daily:"", remaining_passes:"", remaining_days:""});
    const { ticket_id } = useParams();
    const navigate = useNavigate();
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getInfoPerTID(ticket_id);
                console.log(response);
                setTicket(response); 
                console.log(ticket);
            } catch (error) {
                console.error(t("search.errorFetchingTicketInfo"), error);
            }
        };
        fetchData();
    }, [ticket_id]);

  return (
    <Container maxWidth="sm" style={{alignItems: "center", justifyContent:"center"}}>
            <Box sx={{ mt: 4, p: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#f9f9f9',}}>
                <Typography variant="h4" align="center" gutterBottom>
                    {t("search.ticketInfo")}
                </Typography>
                
                <form className="form">
                  <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="name"
                                label={t("search.dateCreated")}
                                value={ticket.create_time}
                                variant="outlined"
                                slotProps={{
                                    input: {
                                      readOnly: true,
                                    },
                                  }}
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                  fullWidth
                                  id="name"
                                  label={t("search.exceedDate")}
                                  value={ticket.exceed_date}
                                  variant="outlined"
                                  slotProps={{
                                      input: {
                                        readOnly: true,
                                      },
                                    }}
                              />
                            </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="name"
                                label={t("search.firstName")}
                                value={ticket.name}
                                slotProps={{
                                    input: {
                                      readOnly: true,
                                    },
                                  }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="lastName"
                                label={t("edit.lastName")}
                                value={ticket.surname}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                        fullWidth
                                        id="phone"
                                        label={t("edit.phoneNumber")}
                                        value={ticket.phone}
                                        slotProps={{
                                            input: {
                                              readOnly: true,
                                            },
                                          }}
                                        variant="outlined"
                                    />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                        fullWidth
                                        id="remaining_passes"
                                        label={t("search.remainingPasses")}
                                        value={ticket.remaining_passes}
                                        slotProps={{
                                            input: {
                                              readOnly: true,
                                            },
                                          }}
                                        variant="outlined"
                                    />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="remaining_days"
                                label={t("search.remainingDays")}
                                value={ticket.remaining_days}
                                slotProps={{
                                    input: {
                                      readOnly: true,
                                    },
                                  }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="daily_passes"
                                label={t("search.remainingDailyPasses")}
                                value={ticket.remaining_daily}
                                slotProps={{
                                    input: {
                                      readOnly: true,
                                    },
                                  }}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="hFrom"
                                label={t("search.hFrom")}
                                value={ticket.h_from}
                                slotProps={{
                                    input: {
                                      readOnly: true,
                                    },
                                  }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="hTo"
                                label={t("search.hTo")}
                                value={ticket.h_to}
                                slotProps={{
                                    input: {
                                      readOnly: true,
                                    },
                                  }}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                
                    <Grid container spacing={2} justifyContent={'flex-end'}>
            
                        <Grid item xs={12} sm={6}>
                            <Button
                                fullWidth
                                type="button"
                                variant="contained"
                                color="error"
                                onClick={() => { navigate('/') }}
                            >
                            {t("search.back")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
  )
}

export default PerTicketNumber
