import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editTicket, editTicketUser, getReaders, getTicket, postRequest, blockTicket } from '../../api/axios';

import { TextField, Button, Box, Typography, Container, Alert, Grid, Dialog, DialogContent, FormControlLabel, Checkbox, DialogActions, DialogTitle } from '@mui/material';
import Error from '../Error/Error';
import Success from '../Success/Success';
import { useTranslation } from 'react-i18next';
import { TextFormatRounded } from '@mui/icons-material';
import { getUsername } from '../../context/AuthFunc';

const EditTicket = () => {
    const { t } = useTranslation('global');

    const { ticket_id } = useParams();
    const [openReadersModal, setOpenReadersModal] = useState(false);
    const [openActiveModal, setOpenActiveModal] = useState(false);
    const [ticket, setTicket] = useState(null);
    const [user, setUser] = useState(null);
    const [phone, setPhone] = useState('');
    const [readers, setReaders] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [succ, setSucc] = useState('');
    const [inactiveReason, setInactiveReason] = useState('');
    const navigate = useNavigate();

    const fetchData = async () => {
        const response = await getTicket(ticket_id);
        setTicket(response.ticket);
        setUser(response.user);
        console.log(response);
        console.log(user);
    };
    const fetchReaders = async () => {
        const response = await getReaders();
        setReaders(response);
    };
    useEffect(() => {
        // Fetch ticket and user data
        fetchData();
        fetchReaders();
    }, [ticket_id]);

    const handleOpenActiveModal = () => setOpenActiveModal(true);
    const handleCloseActiveModal = (submit) => {
        setOpenActiveModal(false); // Close the modal first

        if (submit !== true) {
            setInactiveReason('');
            return;
        }
        if (!inactiveReason.trim()) {
            setErrMsg(t("edit.pleaseProvideReason"));
            setTimeout(() => setErrMsg(''), 3000);
            return;
        }        
        setTicket({...ticket, active : "0"});
    };
    
    const handleOpenReadersModal = () => setOpenReadersModal(true);
    const handleCloseReadersModal = () => setOpenReadersModal(false);

    const handleSave = async (e) => {
        e.preventDefault(); // Prevent default form submission
        
        if (!user.name || !user.surname || !user.phone || !ticket.day_no || !ticket.entry_no || !ticket.daily_no) {
            setErrMsg(t("edit.allFieldsMustBeFilled"));
            setTimeout(() => setErrMsg(''), 3000);
            return;
        }
        try {
            if(ticket.active == "0" && inactiveReason.trim()){
                const params = new URLSearchParams({
                    id: ticket_id,
                    user: getUsername(),
                    desc: inactiveReason,
                  });
                blockTicket(params);
            }
            if (user.phone !== ticket.original_phone) {
                // Phone number changed, create new user
                const updatedUser = { ...user };
                const response = await postRequest('/add/ticket-user', updatedUser);
            if (response.success) {
                const newUserId = response.other;
                const updatedTicket = { ...ticket, id: newUserId };
                // Update ticket with new user id
                console.log(updatedTicket);
                const update = await editTicket(updatedTicket);
                if (update.success) {
                    fetchData();
                    setSucc(t("edit.editSuccessful"));
                    setTimeout(() => setSucc(''), 3000);
                } else {
                    setErrMsg(t("edit.errorDuringEdit"));
                    setTimeout(() => setErrMsg(''), 3000);
                }
            } else {
                setErrMsg(t("edit.errorDuringEdit"));
                setTimeout(() => setErrMsg(''), 3000);
            }
        } else {
            // Phone number not changed, just update the ticket and user
            const updateTU = await editTicketUser(user);
            const updateT = await editTicket(ticket);
            if (updateT.success && updateTU.success) {
                fetchData();
                setSucc(t("edit.editSuccessful"));
                setTimeout(() => setSucc(''), 3000);
            } else {
                setErrMsg(t("edit.errorDuringEdit"));
                setTimeout(() => setErrMsg(''), 3000);
            }
        }
        } catch (error) {
            setErrMsg(t("edit.errorDuringEdit"));
            setTimeout(() => setErrMsg(''), 3000);
        }
    };

    /*const handleCheckboxChange = (readerId, checked) => {
        const updatedReaders = checked
            ? [...ticket.readers, readerId] // Add readerId if checked
            : ticket.readers.filter(id => id !== readerId); // Remove readerId if unchecked
        setTicket({ ...ticket, readers: updatedReaders });
    };*/

    const handleCheckboxChange = (readerId) => {
        let updatedReaders = [...ticket.readers];
        if (readerId === 'all') {
          updatedReaders = readers.map((r) => r.id); // Select all readers
        } 
        else {
          if (updatedReaders.includes(readerId)) {
            updatedReaders = updatedReaders.filter((id) => id !== readerId);
          } else {
            updatedReaders.push(readerId);
          }
        }
        setTicket({...ticket, readers: updatedReaders});
    };
    const handleActiveChange = () =>{
        if(ticket.active == "1"){
            setTicket({...ticket, active : "0"});
        }
        else{
            setTicket({...ticket, active : "1"})
        }
    }
    if (ticket === undefined || ticket == null) return <div>{t("edit.ticketNotAvailable")}</div>;
    if (user == null) return <div>Loading user data...</div>;


    return (

        <Container maxWidth="sm" style={{alignItems: "center", justifyContent:"center"}}>
            <Box sx={{ mt: 4, p: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#f9f9f9',}}>
                <Typography variant="h4" align="center" gutterBottom>
                    {t("edit.editTicket")}
                </Typography>
                
                <form onSubmit={handleSave} className="form">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="name"
                                label={t("edit.dateAndTimeOfTicketIssuance")}
                                value={ticket.create_time}
                                variant="outlined"
                                slotProps={{
                                    input: {
                                      readOnly: true,
                                    },
                                  }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="name"
                                label={t("edit.firstName")}
                                value={user.name}
                                onChange={(e) => setUser({...user, name:e.target.value})}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="lastName"
                                label={t("edit.lastName")}
                                value={user.surname}
                                onChange={(e) => setUser({...user, surname:e.target.value})}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                        fullWidth
                                        id="phone"
                                        label={t("edit.phoneNumber")}
                                        value={user.phone}
                                        onChange={(e) => setUser({...user, phone:e.target.value})}
                                        variant="outlined"
                                    />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                        fullWidth
                                        id="day_no"
                                        label={t("edit.numberOfDaysTheTicketIsValid")}
                                        value={ticket.day_no}
                                        onChange={(e) => setTicket({...ticket, day_no:e.target.value})}
                                        variant="outlined"
                                    />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="entry_no"
                                label={t("edit.numberOfPasses")}
                                value={ticket.entry_no}
                                onChange={(e) => setTicket({...ticket, entry_no:e.target.value})}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="daily_no"
                                label={t("edit.numberOfDailyPasses")}
                                value={ticket.daily_no}
                                onChange={(e) => setTicket({...ticket, daily_no:e.target.value})}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="hFrom"
                                label={t("edit.entryFrom")}
                                value={ticket.h_from}
                                onChange={(e) => setTicket({...ticket, h_from:e.target.value})}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="hTo"
                                label={t("edit.entryUntil")}
                                value={ticket.h_to}
                                onChange={(e) => setTicket({...ticket, h_to:e.target.value})}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Button 
                                variant="outlined" 
                                onClick={handleOpenReadersModal}
                                fullWidth
                            >
                                {t("edit.selectReaders")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button 
                                variant="outlined" 
                                onClick={handleOpenActiveModal}
                                fullWidth
                            >
                                {t("edit.deactivateTicket")}
                            </Button>
                        </Grid>
                    </Grid>
                    {errMsg && (
                                <Box item xs={12}>
                                  <Alert severity="error" onClose={() => setErrMsg('')}>
                                    {errMsg}
                                  </Alert>
                                </Box>
                              )}
                              {succ && (
                                <Box item xs={12}>
                                  <Alert severity="success" onClose={() => setSucc('')}>
                                    {succ}
                                  </Alert>
                                </Box>
                    )}
                    <Dialog open={openActiveModal} onClose={() => handleCloseActiveModal(false)} fullWidth maxWidth="sm">
                        <DialogTitle>{t("edit.deactivateTicket")}</DialogTitle>
                        <DialogContent>
                            
                            <Typography>{t("edit.desc")}</Typography>
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                placeholder={t("edit.enterReason")}
                                value={inactiveReason}
                                onChange={(e) => setInactiveReason(e.target.value)}
                                sx={{
                                    padding: 0, // Ensures no extra padding
                                    "& .MuiOutlinedInput-root": {
                                        padding: "5px", // Adjust padding inside the input
                                    }}}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCloseActiveModal(true)} color="primary" variant="contained">
                                {t("edit.save")}
                            </Button>
                            <Button onClick={() => handleCloseActiveModal(false)} color="primary" variant="contained">
                                {t("edit.cancel")}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openReadersModal} onClose={handleCloseReadersModal} fullWidth maxWidth="sm">
                        <DialogTitle>{t("edit.readers")}</DialogTitle>
                        <DialogContent>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={() => handleCheckboxChange('all')}
                                        checked={ticket.readers.length === readers.length}
                                    />
                                }
                                label={t("editItem.all")}
                            />
                            <Grid container spacing={1}>
                                {readers.map((reader) => (
                                    <Grid item xs={12} sm={6} key={reader.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={ticket.readers.includes(reader.id)}
                                                    onChange={() => handleCheckboxChange(reader.id)}
                                                />
                                            }
                                            label={reader.name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseReadersModal} color="primary" variant="contained">
                                {t("edit.save")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            >
                            {t("edit.save")}
                            </Button>
                        </Grid>
            
                        <Grid item xs={12} sm={6}>
                            <Button
                            fullWidth
                            type="button"
                            variant="contained"
                            color="error"
                            onClick={() => { navigate('/') }}
                            >
                            {t("edit.cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
        /*<div className='edit-form-container'>
            <div className='container-reader'>
                <h1>{t("edit.editTicket")}</h1>
                
                <label className='edit-label-fix'>
                {t("edit.ticket")}
                    <input
                        type="text"
                        value={ticket.ticket_id}
                        readOnly
                    />
                </label>
                <label className='edit-label-fix'>
                {t("edit.dateAndTimeOfTicketIssuance")}
                    <input
                        type="text"
                        value={ticket.create_time}
                        readOnly
                    />
                </label>
                <form>
                    <label className='edit-label'>
                    {t("edit.firstName")}
                        <input
                            type="text"
                            value={user.name}
                            onChange={(e) => setUser({ ...user, name: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                    {t("edit.lastName")}
                        <input
                            type="text"
                            value={user.surname}
                            onChange={(e) => setUser({ ...user, surname: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                    {t("edit.phoneNumber")}
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </label>
                    <label className='edit-label'>
                    {t("edit.numberOfDaysTheTicketIsValid")}
                        <input
                            type="text"
                            value={ticket.day_no}
                            onChange={(e) => setTicket({ ...ticket, day_no: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                    {t("edit.numberOfPasses")}
                        <input
                            type="text"
                            value={ticket.entry_no}
                            onChange={(e) => setTicket({ ...ticket, entry_no: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                    {t("edit.numberOfDailyPasses")}
                        <input
                            type="text"
                            value={ticket.daily_no}
                            onChange={(e) => setTicket({ ...ticket, daily_no: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                    {t("edit.entryFrom")}
                        <input
                            type="time"
                            id="hFrom"
                            value={ticket.h_from}
                            onChange={(e) => setTicket({ ...ticket, h_from: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                    {t("edit.entryUntil")}
                        <input
                            type="time"
                            id="hTo"
                            value={ticket.h_to}
                            onChange={(e) => setTicket({ ...ticket, h_to: e.target.value })}
                        />
                    </label>
                    {readers.map((reader, index) => (
                        <div key={index} className='checkbox-container'>
                            <input
                                type="checkbox"
                                checked={ticket.readers.includes(reader.id)}
                                onChange={(e) => handleCheckboxChange(reader.id, e.target.checked)}
                            />
                            <label>{reader.name}</label>
                        </div>
                    ))}
                    <div className='button-container'>
                        <button type="button" className='reader-button' onClick={handleSave}>{t("edit.save")}</button>
                        <button type="button" className='reader-button cancel-button' onClick={() => navigate('/')}>{t("edit.cancel")}</button>
                    </div>
                </form>
            </div>
            {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
            {succ && <Success message={succ} onClose={() => setSucc('')} />}
        </div>*/
    );
};

export default EditTicket;
