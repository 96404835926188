// src/components/Logout/Logout.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Grid, MenuItem } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';

const Logout = ({ isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setIsLoggedIn(false);
    navigate('/login');
  };

  return (
    isLoggedIn && (
      <MenuItem onClick={handleLogout}>
        <Grid container spacing={2}>
          <Grid xs={6} item sx={{ display: "flex", justifyContent: "flex-start" }}>
            {t("navbar.logout")}
          </Grid>
          <Grid xs={6} item sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LogoutIcon />
          </Grid>
        </Grid>
      </MenuItem>
    )
  );
};

export default Logout;

