import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, Container, Alert, List, ListItem, ListItemText, ListItemIcon, Grid } from '@mui/material';
import { InsertDriveFile as FileIcon } from '@mui/icons-material'
import { getUpdateFiles, updateDatabase } from '../../api/axios';
import { useTranslation } from 'react-i18next';

const UpdateDatabase = () => {
    const { t } = useTranslation('global');

    const [updateFiles, setUpdateFiles] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [isUpdated, setUpdated] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    const fetchUpdateFiles = async () => {
        try {
            const response = await getUpdateFiles();
            if (response && Array.isArray(response)) {
                setUpdateFiles(response);
            }
            if (response.every(update => update.applied === true)) {
                setUpdated(true);
            }
            setErrMsg('');
        }
        catch (error) {
            setErrMsg(t("updateDatabase.errorFetchingUpdates"));
            return false;
        }
    };

    const handleUpdate = async () => {
        const response = await updateDatabase();
        if (!response.success) {
            setErrMsg(t("updateDatabase.errorUpdatingDatabase"));
        }
        else {
            setErrMsg('');
            setSuccMsg(t('updateDatabase.succesfulUpdate'));
            fetchUpdateFiles();
        }
    }

    const parseFileName = (file) => {
        const [year, month, day, hours, minutes, seconds] = file.split("_").map(Number);
        const parsedDate = new Date(year, month - 1, day, hours, minutes, seconds);
        const monthShort = parsedDate.toLocaleString("en-US", { month: "short" });
        const date = `${t('updateDatabase.date')}: ${monthShort}-${String(day).padStart(2, "0")}-${year}`;
        const time = `${t('updateDatabase.time')}: ${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        return { date: date, time: time }
    }

    useEffect(() => {
        fetchUpdateFiles();

    }, [updateFiles]);

    return (
        <Container maxWidth="sm" style={{ alignItems: "center", justifyContent: "center" }}>
            <Box
                sx={{
                    mt: 4,
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: '#f9f9f9',
                }}
                className="container-update-files"
            >
                <Typography variant="h4" align="center" gutterBottom>
                    {t("updateDatabase.title")}
                </Typography>

                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <List sx={{
                            overflow: 'auto',
                            minHeight: 400,
                            maxHeight: 400,
                            width: "80%"
                        }}>
                            {updateFiles.map((update) => (
                                <ListItem key={update.file}>
                                    <ListItemIcon>
                                        <FileIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={parseFileName(update.file).date}
                                        secondary={parseFileName(update.file).time}
                                        sx={{
                                            color: update.applied === true ? "green" : "red"
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    {errMsg && (
                        <Grid item xs={12}>
                            <Alert severity="error" onClose={() => setErrMsg('')}>
                                {errMsg}
                            </Alert>
                        </Grid>
                    )}
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <form onSubmit={handleUpdate} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>


                            <Button type="submit" variant="contained" color="primary" sx={{ width: "50%" }} disabled={isUpdated}>
                                {t("updateDatabase.updateDatabase")}
                            </Button>

                        </form>
                    </Grid>
                </Grid>
            </Box>
        </Container >
    );
};

export default UpdateDatabase;