import React, { useEffect, useRef } from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

Chart.register(CategoryScale, LinearScale, LineController, LineElement, PointElement, Title, Tooltip, Legend);

const ChartComponent = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const { t } = useTranslation("global");

  useEffect(() => {
    // Uništavanje prethodne instance grafikona ako postoji
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Formatiranje datuma u "DD-MM"
    const formattedLabels = data.labels.map((date) => {
      const options = { day: '2-digit', month: '2-digit' };
      return new Intl.DateTimeFormat('sr', options).format(new Date(date));
    });

    const processData = (counts) => {
      let startIndex = counts?.findIndex(value => value !== 0);
      let endIndex = counts?.length - 1 - counts?.slice().reverse().findIndex(value => value !== 0);

      return counts?.map((value, index) => {
        if (index < startIndex || index > endIndex) {
          return null; // Hide these points
        }
        return value;
      });
    };


    // Process data so that the line starts from the first non-zero value
    const processedCounts0 = processData(data.counts[0]);
    const processedCounts1 = processData(data.counts[1]);
    const processedCounts2 = processData(data.counts[2]);
    
    const ctx = chartRef.current.getContext('2d');
    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: formattedLabels, // Formatirani datumi
        datasets: [
          {
            label: t('reportSelection.soldTickets'),
            data: processedCounts0,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            fill: false,
            pointRadius: 0,
            pointHoverRadius: 5,
          },
          {
            label: t('reportSelection.successfullyPassedTickets'),
            data: processedCounts1,
            backgroundColor: 'rgba(192, 118, 75, 0.2)',
            borderColor: 'rgba(192, 118, 75, 1)',
            borderWidth: 1,
            fill: false,
            pointRadius: 0,
            pointHoverRadius: 5,
          },
          {
            label: t('reportSelection.passedTickets'),
            data: processedCounts2,
            backgroundColor: 'rgba(167, 65, 141, 0.2)',
            borderColor: 'rgba(167, 65, 141, 1)',
            borderWidth: 1,
            fill: false,
            pointRadius: 0,
            pointHoverRadius: 5,
          }
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true
          },
          
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        interaction: {
          mode: 'nearest', // Make hover work at the nearest point
          intersect: false, // Allow hovering anywhere on the line
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        },
      },
    });

    // Uništavanje instance pri unmount-u
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, t]);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '300px' }}>
        <canvas ref={chartRef} style={{ maxWidth: '100%', maxHeight: '100%', padding: '16px'}}></canvas>
    </Box>);
};

export default ChartComponent;
