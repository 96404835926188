import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Container, Alert } from '@mui/material';
import { getMailSettings, postRequest, getDaysHIK, postDaysHIK } from '../../api/axios';
import { useTranslation } from 'react-i18next';
import './Settings.css';

const Settings = () => {
    const { t } = useTranslation('global');

    const [host, setHost] = useState('');
    const [days, setDays] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [port, setPort] = useState('');
    const [succ, setSucc] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const fetchMailSettings = async () => {
        try {
            const response = await getMailSettings();
            const mailSettings = {
                ...response
            };
            setHost(mailSettings.config_data[0].vrednost);
            setUsername(mailSettings.config_data[1].vrednost);
            setPassword(mailSettings.config_data[2].vrednost);
            setPort(mailSettings.config_data[3].vrednost);
        }
        catch (error) {
            setErrMsg(t("mailSettings.errorGettingSettings"));
            return false;
        }
    };
    const fetchDays = async () =>{
        try {
            const response = await getDaysHIK();
            setDays(response);
        }
        catch (error) {
            setErrMsg(t("mailSettings.errorGettingSettings"));
            return false;
        }
    }

    useEffect(() => {
        fetchDays();
        fetchMailSettings();
    }, []);

    const handleHikSubmit = async (event) => {
        event.preventDefault();
        if(days  === ''){
            setErrMsg(t("mailSettings.allFieldsAreRequired"));
            return;
        }
        const formData = new URLSearchParams();
        formData.append("value", days);
        const response = postDaysHIK(formData);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (host === '' || username === '' || password === '' || port === '') {
            setErrMsg(t("mailSettings.allFieldsAreRequired"));
            return;
        }
        if (!(/^\d*$/.test(port))) {
            setErrMsg(t("mailSettings.portMustContainDigitsOnly"));
            return;
        }

        const formData = new URLSearchParams();
        formData.append('mailConfigs[0][parametar]', 'mail_host');
        formData.append('mailConfigs[0][vrednost]', host);
        formData.append('mailConfigs[1][parametar]', 'mail_username');
        formData.append('mailConfigs[1][vrednost]', username);
        formData.append('mailConfigs[2][parametar]', 'mail_password');
        formData.append('mailConfigs[2][vrednost]', password);
        formData.append('mailConfigs[3][parametar]', 'mail_port');
        formData.append('mailConfigs[3][vrednost]', port);

        const response = await postRequest("/mail-configs", formData);
        if (!response.success) {
            setErrMsg(t("mailSettings.errorSavingSettings"));
        } else {
            setHost('');
            setUsername('');
            setPassword('');
            setPort('');
            setSucc(t("mailSettings.successfullyAdded"));
            setErrMsg('');
        }
    };

    return (
        <Container maxWidth="sm" style={{ alignItems: "center", justifyContent: "center" }}>
            <Box
                sx={{
                    mt: 4,
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: '#f9f9f9',
                }}
                className="container-mail-settings"
            >
                <Typography variant="h4" align="center" gutterBottom>
                    {t("mailSettings.mailSettings")}
                </Typography>

                <form onSubmit={handleSubmit} className="form">
                    <Box item xs={12}>
                        <TextField
                            fullWidth
                            id="host"
                            label={t("mailSettings.host")}
                            value={host}
                            onChange={(e) => setHost(e.target.value)}
                            variant="outlined"
                        />
                    </Box>

                    <Box item xs={12}>
                        <TextField
                            fullWidth
                            id="username"
                            label={t("mailSettings.username")}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            variant="outlined"
                        />
                    </Box>

                    <Box item xs={12}>
                        <TextField
                            fullWidth
                            type="password"
                            id="password"
                            label={t("mailSettings.password")}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            variant="outlined"
                        />
                    </Box>

                    <Box item xs={12}>
                        <TextField
                            fullWidth
                            id="port"
                            label={t("mailSettings.port")}
                            value={port}
                            onChange={(e) => setPort(e.target.value)}
                            variant="outlined"
                        />
                    </Box>

                    

                    <Box item xs={12}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            {t("mailSettings.applyChanges")}
                        </Button>
                    </Box>
                </form>
            </Box>
            <Box
                sx={{
                    mt: 4,
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: '#f9f9f9',
                }}
                className="container-hik-settings"
            >
                <Typography variant="h4" align="center" gutterBottom>
                    {t("hik.hikSettings")}
                </Typography>

                <form onSubmit={handleHikSubmit} className="form">
                    <Box item xs={12}>
                        <TextField
                            fullWidth
                            id="days"
                            label={t("hik.days")}
                            value={days}
                            onChange={(e) => setDays(e.target.value)}
                            variant="outlined"
                        />
                    </Box>

                    

                    <Box item xs={12}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            {t("hik.apply")}
                        </Button>
                    </Box>
                </form>
            </Box>
            {errMsg && (
                        <Box item xs={12}>
                            <Alert severity="error" onClose={() => setErrMsg('')}>
                                {errMsg}
                            </Alert>
                        </Box>
                    )}
                    {succ && (
                        <Box item xs={12}>
                            <Alert severity="success" onClose={() => setSucc('')}>
                                {succ}
                            </Alert>
                        </Box>
                    )}
        </Container>
    );
}

export default Settings;