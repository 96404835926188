import React, {useEffect, useState} from "react";
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import {getTicketConfig, sendTicketConfig} from "../../api/axios";

const TicketDesign = ({ ticketData = {} }) => {
    const POS_WIDTH = 300;
    const POS_HEIGHT = 400;

    // Početne vrednosti teksta
    const [initialTexts, setInitialTexts] = useState([
        { id: 1, content: "category", x: 10, y: 10, fontSize: 14 },
        { id: 2, content: "kind", x: 10, y: 30, fontSize: 14 },
        { id: 3, content: "price", x: 10, y: 50, fontSize: 14 },
        { id: 4, content: "endDate", x: 10, y: 70, fontSize: 14 },
        { id: 5, content: "text", x: 10, y: 90, fontSize: 16 }
    ]);

    const [config, setConfig] = useState({
        width: POS_WIDTH,
        height: POS_HEIGHT,
        backgroundColor: "#ffffff",
        fontFamily: "Arial",
        textColor: "#000000",
        qrPositionX: 150,
        qrPositionY: 120,
        qrSize: 60,
        texts: initialTexts, // Koristi inicijalne vrednosti
    });

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await getTicketConfig();


                if (response && response.texts) { // Provera da li postoji texts
                    setInitialTexts(response.texts); // Postavljam inicijalni tekst
                    setConfig({
                        width: response.width || POS_WIDTH,
                        height: response.height || POS_HEIGHT,
                        backgroundColor: response.backgroundColor || "#ffffff",
                        fontFamily: response.fontFamily || "Arial",
                        textColor: response.textColor || "#000000",
                        qrPositionX: response.qrPositionX || 150,
                        qrPositionY: response.qrPositionY || 120,
                        qrSize: response.qrSize || 60,
                        texts: response.texts || [] // Osiguravam da ne ostane undefined
                    });
                } else {
                    console.warn("Backend nije vratio očekivane podatke.");
                }
            } catch (error) {
                console.error("Greška pri učitavanju konfiguracije:", error);
            }
        };

        fetchConfig();
    }, []);

    const handleChange = (field, value) => {
        setConfig((prev) => ({ ...prev, [field]: value }));
    };

    const handleTextChange = (id, key, value) => {
        setConfig((prev) => ({
            ...prev,
            texts: prev.texts.map((text) =>
                text.id === id ? { ...text, [key]: value } : text
            ),
        }));
    };

    const handleSendConfig = async () => {
        try {
            await sendTicketConfig(config);
            //alert("Konfiguracija uspešno sačuvana!");
        } catch (error) {
            console.error("Greška pri slanju konfiguracije:", error);
        }
    };

    return (
        <div style={{ padding: "20px", display: "flex", gap: "30px", alignItems: "flex-start" }}>
            {/* Panel sa podešavanjima */}
            <div style={{ flex: 1 }}>
                <Typography variant="h5" style={{ color: "#2c3e50", marginBottom: "10px" }}>Dizajn karte za POS štampač</Typography>

                <Typography variant="h6" style={{ marginTop: "10px", color: "#34495e" }}>Podešavanja QR koda</Typography>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px", backgroundColor: "#ecf0f1", borderRadius: "5px" }}>
                    <label style={{ fontWeight: "bold" }}>X koordinata QR koda</label>
                    <input
                        type="range"
                        min="0"
                        max={POS_WIDTH - 50}
                        value={config.qrPositionX}
                        onChange={(e) => handleChange("qrPositionX", Number(e.target.value))}
                    />
                    <label style={{ fontWeight: "bold" }}>Y koordinata QR koda</label>
                    <input
                        type="range"
                        min="0"
                        max={POS_HEIGHT - 50}
                        value={config.qrPositionY}
                        onChange={(e) => handleChange("qrPositionY", Number(e.target.value))}
                    />
                    <label style={{ fontWeight: "bold" }}>Veličina QR koda</label>
                    <input
                        type="range"
                        min="20"
                        max="100"
                        value={config.qrSize}
                        onChange={(e) => handleChange("qrSize", Number(e.target.value))}
                    />
                </div>

                {/* Tabela za podešavanje teksta */}
                <Typography variant="h6" style={{ marginTop: "20px", color: "#34495e" }}>Tekst na karti</Typography>
                <TableContainer component={Paper} style={{ maxWidth: "850px", maxHeight: "550px", overflow: "auto", backgroundColor: "#ecf0f1", borderRadius: "5px" }}>
                    <Table size="medium">
                        <TableHead style={{ backgroundColor: "#2c3e50" }}>
                            <TableRow>
                                <TableCell style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>Tekst</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>X</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>Y</TableCell>
                                <TableCell style={{ color: "white", fontWeight: "bold", textAlign: "center" }}>Veličina</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {config.texts.map((text) => (
                                <TableRow key={text.id} style={{ backgroundColor: "#ffffff" }}>
                                    <TableCell>
                                        <TextField value={text.content} />
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                        <input
                                            type="range"
                                            min="0"
                                            max={POS_WIDTH - 50}
                                            value={text.x}
                                            onChange={(e) => handleTextChange(text.id, "x", Number(e.target.value))}
                                        />
                                        <TextField
                                            type="number"
                                            value={text.x}
                                            onChange={(e) => handleTextChange(text.id, "x", Number(e.target.value))}
                                            size="small"
                                            style={{ width: "60px", marginLeft: "10px" }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                        <input
                                            type="range"
                                            min="0"
                                            max={POS_HEIGHT - 50}
                                            value={text.y}
                                            onChange={(e) => handleTextChange(text.id, "y", Number(e.target.value))}
                                        />
                                        <TextField
                                            type="number"
                                            value={text.y}
                                            onChange={(e) => handleTextChange(text.id, "y", Number(e.target.value))}
                                            size="small"
                                            style={{ width: "60px", marginLeft: "10px" }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                        <TextField
                                            type="number"
                                            value={text.fontSize}
                                            onChange={(e) => handleTextChange(text.id, "fontSize", Number(e.target.value))}
                                            size="small"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendConfig}
                    style={{ marginTop: 20, backgroundColor: "#2980b9", color: "white" }}
                >
                    Sačuvaj dizajn
                </Button>
            </div>

            {/* Pregled karte */}
            <div
                style={{
                    width: `${POS_WIDTH}px`,
                    height: `${POS_HEIGHT}px`,
                    backgroundColor: config.backgroundColor,
                    border: "2px solid #2c3e50",
                    position: "relative",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "2px 2px 10px rgba(0,0,0,0.2)"
                }}
            >
                {/* QR kod */}
                <div
                    style={{
                        position: "absolute",
                        left: `${config.qrPositionX}px`,
                        top: `${config.qrPositionY}px`,
                        width: `${config.qrSize}px`,
                        height: `${config.qrSize}px`,
                        backgroundColor: "black",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "10px",
                        borderRadius: "5px"
                    }}
                >
                    QR
                </div>

                {/* Dinamički tekstovi */}
                {config.texts.map((text) => (
                    <div key={text.id} style={{ position: "absolute", left: `${text.x}px`, top: `${text.y}px`, color: config.textColor, fontSize: `${text.fontSize}px`, fontFamily: config.fontFamily }}>
                        {text.content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TicketDesign;
