import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Container,
    CircularProgress,
    Alert,
    TextField,
    Button,
    Grid,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { getTemplateGroupEvents, addTemplateGroupEvent, getGroupById, getTemplateGroups } from "../../api/axios";
import { useTranslation } from "react-i18next";
import DataTable from "../Table/DataTable.jsx";

const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    const hours = d.getHours().toString().padStart(2, "0");
    const minutes = d.getMinutes().toString().padStart(2, "0");
    const seconds = d.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const getFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

const TemplateGroupEvents = () => {
    const { t } = useTranslation("global");
    const [templateGroupEvents, setTemplateGroupEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newDescription, setNewDescription] = useState("");
    const [newStart, setNewStart] = useState(getFormattedDate());
    const [newEnd, setNewEnd] = useState(getFormattedDate());
    const [adding, setAdding] = useState(false);
    const [addError, setAddError] = useState(null);
    const [blockedGroups, setBlockedGroups] = useState([]); // State for blocked groups
    const [openBlockedModal, setOpenBlockedModal] = useState(false); // Modal for selecting blocked groups
    const [openAllowedModal, setOpenAllowedModal] = useState(false); // Modal for selecting allowed groups

    useEffect(() => {
        const fetchData = async () => {
            try {
                const events = await getTemplateGroupEvents();
                const enrichedEvents = await Promise.all(events.map(async (event) => {
                    if (event.blocked_groups) {
                        const groupIds = event.blocked_groups.split(",");
                        const groupNames = await Promise.all(groupIds.map(async (id) => {
                            try {
                                const group = await getGroupById(id);
                                return group.name;
                            } catch {
                                return "Unknown Group";
                            }
                        }));
                        return { ...event, blocked_groups: groupNames.join(", ") };
                    }
                    return event;
                }));
                setTemplateGroupEvents(enrichedEvents);
            } catch (err) {
                setError(t("templateGroupEvents.errorFetching"));
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [t]);

    const handleAddGroupEvent = async () => {
        if (!newDescription.trim() || !newStart.trim() || !newEnd.trim()) return;
        setAdding(true);
        setAddError(null);

        try {
            const params = new URLSearchParams();
            params.append("description", newDescription);
            params.append("start", formatDate(newStart));
            params.append("end", formatDate(newEnd));
            params.append("blocked_groups", blockedGroups.join(",")); // Only blocked groups are sent
            await addTemplateGroupEvent(params);

            window.location.reload(); // Refresh after adding
        } catch (err) {
            setAddError(t("templateGroupEvents.errorAdding"));
        } finally {
            setAdding(false);
        }
    };

    const handleSelectBlockedGroups = () => {
        setOpenBlockedModal(true); // Open modal for selecting blocked groups
    };

    const handleSelectAllowedGroups = () => {
        setOpenAllowedModal(true); // Open modal for selecting allowed groups
    };

    const columns = [
        { Header: t("templateGroupEvents.description"), accessor: "description" },
        { Header: t("templateGroupEvents.start"), accessor: "start" },
        { Header: t("templateGroupEvents.end"), accessor: "end" },
        { Header: t("templateGroupEvents.blockedGroups"), accessor: "blocked_groups" },
    ];

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    mt: 4,
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: "#f9f9f9",
                }}
            >
                <Box mt={4}>
                    <Typography variant="h4" gutterBottom>
                        {t("templateGroupEvents.addNewEvent")}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t("templateGroupEvents.start")}
                                type="datetime-local"
                                variant="outlined"
                                value={newStart}
                                onChange={(e) => setNewStart(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t("templateGroupEvents.end")}
                                type="datetime-local"
                                variant="outlined"
                                value={newEnd}
                                onChange={(e) => setNewEnd(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label={t("templateGroupEvents.newDescription")}
                                variant="outlined"
                                value={newDescription}
                                onChange={(e) => setNewDescription(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                            <Button
                                fullWidth
                                color="primary"
                                onClick={handleSelectBlockedGroups}
                                disabled={adding}
                                sx={{ alignSelf: "center", border: "1px solid" }}
                            >
                                {t("templateGroupEvents.selectBlockedGroups")}
                            </Button>
                        </Grid>
                        {/*<Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                            <Button
                                fullWidth
                                color="primary"
                                onClick={handleSelectAllowedGroups}
                                disabled={adding}
                                sx={{ alignSelf: "center", border: "1px solid" }}
                            >
                                {t("templateGroupEvents.selectAllowedGroups")}
                            </Button>
                        </Grid>*/}
                        <Grid item xs={12} sm = {6} sx={{ display: "flex" }}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleAddGroupEvent}
                                disabled={adding}
                                sx={{ alignSelf: "center" }}
                            >
                                {t("templateGroupEvents.addButton")}
                            </Button>
                        </Grid>
                            <SelectGroupsModal
                                open={openBlockedModal}
                                onClose={() => setOpenBlockedModal(false)}
                                selectedGroups={blockedGroups}
                                setSelectedGroups={setBlockedGroups}
                                title={t("templateGroupEvents.selectBlockedGroups")}
                                isBlockedModal={true} // Indicates this is the blocked groups modal
                            />
                            <SelectGroupsModal
                                open={openAllowedModal}
                                onClose={() => setOpenAllowedModal(false)}
                                selectedGroups={blockedGroups}
                                setSelectedGroups={setBlockedGroups}
                                title={t("templateGroupEvents.selectAllowedGroups")}
                                isBlockedModal={false} // Indicates this is the allowed groups modal
                            />
                    </Grid>  
                    {addError && <Alert severity="error" sx={{ mt: 2 }}>{addError}</Alert>}
                </Box>
                {loading && <CircularProgress sx={{ display: "block", mx: "auto", mt: 2 }} />}
                {error && <Alert severity="error">{error}</Alert>}
                <Divider sx={{ my: 4 }}></Divider>
                {!loading && !error && (
                    <DataTable
                        initialData={templateGroupEvents}
                        columns={columns}
                        entityName="templateGroupEvents"
                        h2={t("templateGroupEvents.templateGroupEventsList")}
                    />
                )}
            </Box>
        </Container>
    );
};

const SelectGroupsModal = ({ open, onClose, selectedGroups, setSelectedGroups, title, isBlockedModal }) => {
    const { t } = useTranslation("global");
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const result = await getTemplateGroups();
                setGroups(result);
            } catch (err) {
                setError(t("templateGroupEvents.errorFetchingGroups"));
            } finally {
                setLoading(false);
            }
        };

        if (open) fetchGroups();
    }, [open, t]);

    const handleToggle = (groupId) => {
        setSelectedGroups((prev) =>
            prev.includes(groupId) ? prev.filter((id) => id !== groupId) : [...prev, groupId]
        );
    };

    const handleSelectAllToggle = () => {
        if (selectAll) {
            setSelectedGroups([]);
        } else {
            const allGroupIds = filteredGroups.map((group) => group.id);
            setSelectedGroups(allGroupIds);
        }
        setSelectAll(!selectAll);
    };

    const filteredGroups = groups.filter((group) =>
        group.name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs = {12} sm = {8} sx={{p:0, m:0}}>
                        <TextField
                            label={t("templateGroupEvents.search")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs = {12} sm = {4} sx = {{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <FormControlLabel                   
                            control={
                                <Checkbox 
                                    checked={selectAll}
                                    onChange={handleSelectAllToggle}
                                />
                            }
                            label={t("templateGroupEvents.selectAll")}
                        />
                    </Grid>             
                </Grid>
                {loading ? (
                    <CircularProgress sx={{ display: "block", mx: "auto", mt: 2 }} />
                ) : error ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <FormGroup>
                        {filteredGroups.map((group) => (
                            <FormControlLabel
                                key={group.id}
                                control={
                                    <Checkbox
                                        checked={selectedGroups.includes(group.id)}
                                        onChange={() => handleToggle(group.id)}
                                    />
                                }
                                label={group.name}
                            />
                        ))}
                    </FormGroup>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" variant="contained">
                    {t("templateGroupEvents.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};



export default TemplateGroupEvents;