import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  Box,
  Typography,
  IconButton,
  Checkbox
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { deleteItem, deleteReader, openBarrier, getCountExits, countExit } from '../../api/axios';
import { hasPermission } from '../../context/AuthFunc';
import { PERMISSIONS } from '../../constants/const';

const DataTable = ({ initialData, columns, entityName, h2 }) => {
  const { t } = useTranslation('global');
  const [data, setData] = useState(initialData);
  const [countExits, setCountExits] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getCount();
  }, []);

  const handleEdit = (id) => {
    navigate(`/${entityName}/edit/${id}`);
  };
  const getCount = async () =>{
    const res = await getCountExits();
    setCountExits(res);
    return res;
  }

  const handleDelete = async (id) => {
    if (window.confirm(t('table.areYouSure'))) {
      const params = new URLSearchParams();
      params.append("id", id);

      let result;
      if (entityName === "readers") {
        result = await deleteReader(params);
      } else if (entityName === "item") {
        result = await deleteItem(params);
      }
      console.log(result);
      if (result?.success) {
        // Filtriraj stavke kako bi uklonio obrisanu stavku
        setData((prevData) => prevData.filter((item) => item.id !== id));
        console.log(t('table.deletedSuccessfully'));
      } else {
        console.error(t('table.deleteFailed'));
      }
    }
  };

  const handleOpen = async (id) => {
    const reader = data.find((item) => item.id === id);
    if (reader) {
      const params = new URLSearchParams();
      params.append("command","OPEN_BARRIER");
      params.append("serial_number", reader.serial_num)
      try {
        const res = await openBarrier(params);
        console.log(res);
      } catch (error) {
        console.error('Error opening barrier:', error);
      }
    } else {
      alert(t('table.readerNotFound'));
    }
  };
  const handleExits = async (checked) =>{
    let params = new URLSearchParams();    
    let res;
    if(checked){
      params.append('value', '1');
      res = await countExit(params);
      setCountExits(true)
    }
    else{
      params.append('value', '0');
      res = await countExit(params);
      setCountExits(false)
    }
    console.log(countExits)
  };

  const handleOpenAll = async () => {
    for (const item of data) {
      await handleOpen(item.id);
    }
  };

  return (
    <Box fullWidth>
      <Typography variant="h4" gutterBottom>
        {h2}
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: "transparent" }} fullWidth>
        <Table sx={{ backgroundColor: "transparent" }} fullWidth>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.accessor} variant="head">
                  {column.Header}
                </TableCell>
              ))}
              {!(entityName==="templateGroupEvents") && (
              <TableCell variant="head">{t("table.actions")}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id}>
                {columns.map((column) => (
                  <TableCell key={column.accessor}>
                    {column.accessor === "active" || column.accessor === "anti_passback"
                      ? item[column.accessor] === "1" 
                        ? t("table.yes") 
                        : t("table.no")
                      : item[column.accessor]}
                  </TableCell>
                  
                ))}
                {!(entityName==="templateGroupEvents") &&(
                <TableCell>
                  <IconButton
                    color="inherit"
                    onClick={() => handleEdit(item.id)}
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>
                  {hasPermission(PERMISSIONS.DELETE_ITEM) && (<IconButton
                    color="inherit"
                    onClick={() => handleDelete(item.id)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>)}
                  {(entityName==="readers") && (
                    <IconButton
                    color="inherit"
                    onClick={() => handleOpen(item.id)}
                    aria-label="open"
                  >
                    <LockOpenIcon />
                  </IconButton>
                  )}
                </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(entityName === "readers") && (
            <Box width="100%" display="flex" justifyContent="flex-end" mt={4} mb={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenAll}
                startIcon={<LockOpenIcon />}
              >
                {t("table.openAll")}
              </Button>
            </Box>
          )}
      {(entityName === "readers") && (
        <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center" mt={4} mb={4}>
          <Typography>
            {t('table.exits')}
          </Typography>
          <Checkbox
            onChange={(e) => handleExits(e.target.checked)}
            checked = {countExits}
          />
        </Box>
      )}
    </Box>
  );
};

export default DataTable;