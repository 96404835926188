import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Checkbox, Container, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Box, MenuItem, Select } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { getReaders, postRequest, ticketSendEmail, getMode, getTemplateGroups } from '../../api/axios';
import Error from '../Error/Error';
import Success from '../Success/Success';
import { useTranslation } from 'react-i18next';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const QRGenerateTicket = () => {
    const { t } = useTranslation('global');

    const [surname, setSurname] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [hFrom, setHFrom] = useState('08:00');
    const [hTo, setHTo] = useState('19:00');
    const [dayNo, setDayNo] = useState('30');
    const [entryNo, setEntryNo] = useState('30');
    const [dailyNo, setDailyNo] = useState('0');
    const [ticketCount, setTicketCount] = useState('1');
    const [email, setEmail] = useState('');
    const [readers, setReaders] = useState([]);
    const [selectedReaders, setSelectedReaders] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [succ, setSucc] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [qrCodes, setQrCodes] = useState([]);
    const [tickets, setTickets] = useState([]); // Čuva podatke o svim generisanim kartama
    const [templateGroups, setTemplateGroups] = useState([]);
    const [selectedTemplateGroup, setSelectedTemplateGroup] = useState("");
    const [registrationPlates, setRegistrationPlates] = useState("");
    
    const [mode, setMode] = useState("0");
    const [changeTime, setChangeTime] = useState(() => {
            const today = new Date().toISOString().split('T')[0];
            return `${today} 00:00:00`;
        });
    const ticketRefs = useRef([]);

    const [isAllPdfGenerated, setIsAllPdfGenerated] = useState(false);
    const [pdfFiles, setPdfFiles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const readers = await getReaders();
                const groups = await getTemplateGroups();
                const mode = await getMode();
                setMode(mode);
                setTemplateGroups(groups || []);
                setReaders(readers);
                setSelectedReaders(readers.map(reader => reader.id));
            } catch (error) {
                console.error(t("newTicket.errorFetchingReaders"), error);
            }
        };

        fetchData();
    }, []);

    const generatePDF = async (index) => {
    
        const ticketElement = ticketRefs.current[index];
        if (!ticketElement) {
            return null;
        }
    
        // Pretvaranje DOM elementa u sliku
        const canvas = await html2canvas(ticketElement);
        const imgData = canvas.toDataURL("image/png");
    
        // Dodavanje slike u PDF
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0); // Podesite dimenzije prema potrebama
    
        // Otvoriti PDF u novom prozoru
        const pdfBlob = pdf.output("blob");
    
        const pdfUrl = URL.createObjectURL(pdfBlob);
    
        return pdfBlob;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const isValidDateTime = (value) => {
            const date = new Date(value.replace(' ', 'T')); // Zamenite razmak sa 'T' za ISO standard
            return !isNaN(date.getTime());
        };

        if (!isValidDateTime(changeTime)) {
            setErrMsg(t("newTicket.invalidChangeTimeFormat"));
            return;
        }

        let newTickets = []; // Čuva podatke za nove karte
    
        for (let i = 0; i < ticketCount; i++) {
            const newTicketId = `${Date.now()}${Math.floor(100 + Math.random() * 900)}`;
    
            const newTicket = {
                ticket_id: newTicketId,
                surname,
                name,
                phone,
                h_from: `${hFrom}:00`,
                h_to: `${hTo}:00`,
                day_no: dayNo,
                entry_no: entryNo,
                daily_no: dailyNo,
                readers: selectedReaders,
                change_time: changeTime,
                create_time: new Date().toISOString().replace('T', ' ').split('.')[0],
                template_id: selectedTemplateGroup,
                registration: registrationPlates
            };
    
            try {
                const response = await postRequest('/write-qr', new URLSearchParams(newTicket));
                if (!response.success) {
                    setErrMsg(t("newTicket.somethingWentWrong"));
                    return;
                }
                newTickets.push(newTicket); // Dodaj kartu u niz
            } catch (error) {
                setErrMsg(t("newTicket.failedToAddTicket"));
                return;
            }
        }
    
        setTickets(newTickets); // Ažuriraj state sa svim generisanim kartama
        setSucc(t("newTicket.successfullyAddedAndSentToEmail"));
    };

    const handleReaderChange = (e) => {
        const value = e.target.value;
        if (value === 'all') {
            setSelectedReaders(selectedReaders.length === readers.length ? [] : readers.map(reader => reader.id));
        } else {
            setSelectedReaders((prev) =>
                prev.includes(value) ? prev.filter((id) => id !== value) : [...prev, value]
            );
        }
    };

    const handleSendEmail = async () => {
        if (!email || tickets.length === 0) {
            alert("Please provide an email address and have at least one ticket.");
            return;
        }

        // Funkcija koja generiše PDF-ove i postavlja stanje
        const generateAllPdf = async () => {
            const generatedFiles = await Promise.all(
                tickets.map(async (_, index) => {
                    const pdfBlob = await generatePDF(index);
                    if (!pdfBlob) {
                        return null;  // Ako PDF nije generisan, preskoči
                    }
                    return new File([pdfBlob], `ticket-${index + 1}.pdf`, { type: "application/pdf" });
                })
            );

            // Filtriraj null vrednosti (ako neki PDF nije generisan)
            const validFiles = generatedFiles.filter(file => file !== null);

            if (validFiles.length === 0) {
                alert("No valid PDF files to send.");
                return;
            }

            setPdfFiles(validFiles);
            setIsAllPdfGenerated(true);  // Svi PDF-ovi su sada generisani
        };

        // Generisanje PDF-ova
        await generateAllPdf();
    };

    // Kada su svi PDF-ovi generisani, šaljemo email
    useEffect(() => {
        if (isAllPdfGenerated && pdfFiles.length > 0) {
            const formData = new FormData();
            formData.append("email", email);
            pdfFiles.forEach((file) => formData.append("files[]", file));


            ticketSendEmail(formData)
                .then(() => {
                    setEmail('')
                })
                .catch(() => {
                    setEmail('')
                });
        }
    }, [isAllPdfGenerated, pdfFiles]);
    

    return (
        <Container className="container-ticket" sx={{ p: 3, mt: 4 }} maxWidth="sm">
            <Typography variant="h4" sx={{mb:4}}>{t("newTicket.newPaperTicket")}</Typography>
            <form onSubmit={handleSubmit} style={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField fullWidth label={t("newTicket.firstName")} value={name} onChange={(e) => setName(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth label={t("newTicket.lastName")} value={surname} onChange={(e) => setSurname(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label={t("newTicket.phoneNumber")} value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="time" fullWidth label={t("newTicket.entryFrom")} value={hFrom} onChange={(e) => setHFrom(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="time" fullWidth label={t("newTicket.entryUntil")} value={hTo} onChange={(e) => setHTo(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="datetime" label={t("newTicket.changeTime")} fullWidth value={changeTime} 
                        onChange={(e) => setChangeTime(e.target.value)}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth label={t("newTicket.numberOfDaysTheTicketIsValid")} value={dayNo} onChange={(e) => setDayNo(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth label={t("newTicket.totalNumberOfEntries")} value={entryNo} onChange={(e) => setEntryNo(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth label={t("newTicket.numberOfDailyEntries")} value={dailyNo} onChange={(e) => setDailyNo(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type="number" fullWidth label={t("newTicket.ticketCount")} value={ticketCount} onChange={(e) => setTicketCount(e.target.value)} />
                    </Grid>
                    {((mode==='1') && (
                    <>
                    <Grid item xs={12} sm={6}>
                    <Select
                        fullWidth
                        value={selectedTemplateGroup} // Koristi selectedTemplateGroup kao vrednost
                        onChange={(e) => setSelectedTemplateGroup(e.target.value)} // Postavi samo ID selektovane grupe
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                        {t("newTicket.selectGroup")}
                        </MenuItem>
                        {templateGroups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                            {group.name}
                        </MenuItem>
                        ))}
                    </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        label={t("newTicket.registrationPlates")}
                        fullWidth
                        value={registrationPlates}
                        onChange={(e) => setRegistrationPlates(e.target.value)}
                    />
                    </Grid>
                    </>
                    ))}
                    <Grid item xs={12}>
                        <Button variant="outlined" onClick={() => setIsModalOpen(true)}>{t("newTicket.selectReader")}</Button>
                    </Grid>
                    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} fullWidth maxWidth="sm">
    <DialogTitle>{t("newTicket.selectReader")}</DialogTitle>
    <DialogContent>
        <Grid container spacing={2} alignItems="center">
            {/* "Select All" checkbox */}
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedReaders.length === readers.length}
                            onChange={handleReaderChange}
                            value="all"
                        />
                    }
                    label={t("newTicket.all")}
                />
            </Grid>

            {/* Individual checkboxes */}
            {readers.map((reader) => (
                <Grid item xs={12} sm={6} key={reader.id}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedReaders.includes(reader.id)}
                                onChange={handleReaderChange}
                                value={reader.id}
                            />
                        }
                        label={reader.name}
                    />
                </Grid>
            ))}
        </Grid>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => setIsModalOpen(false)} sx={{backgroundColor:"#1976d2", color:"white"}}>{t("newTicket.ok")}</Button>
    </DialogActions>
</Dialog>
                    <Box item xs={12} sx={{ width:"100%", display:"flex", justifyContent:"flex-end"}}>
                        <Button sx={{ width:"50%"}} variant="contained" type="submit">{t("newTicket.add")}</Button>
                    </Box>
                </Grid>
            </form>
            {tickets.length > 0 && (
    <Box mt={3}>
        <Typography variant="h6" gutterBottom>
            {t("newTicket.generatedTickets")}
        </Typography>
        {tickets.map((ticket, index) => (
            <Box 
                key={index} 
                ref={(el) => (ticketRefs.current[index] = el)} // Dodeljujemo ref
                sx={{ 
                    border: '1px solid #ccc', 
                    borderRadius: '8px', 
                    padding: 2, 
                    marginBottom: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
                {/* Leva strana: QR kod */}
                <Box>
                <QRCodeSVG value={ticket.ticket_id} style={{ margin: '0px', width: 70, height: 70 }} />
                </Box>

                {/* Desna strana: Detalji karte */}
                <Box sx={{ flex: 1, marginLeft: 2 }}>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.nameAndLastname")}</strong> {`${ticket.name} ${ticket.surname}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.phoneNumber")}</strong> {ticket.phone}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.entryTime")}</strong> {`${ticket.h_from} - ${ticket.h_to}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.changeTime")}</strong> {`${ticket.change_time}`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.numberOfDaysTheTicketIsValid")}</strong> {ticket.day_no}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.totalNumberOfEntries")}</strong> {ticket.entry_no}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.numberOfDailyEntries")}</strong> {ticket.daily_no}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.85rem' }}>
                        <strong>{t("newTicket.readers")}</strong>{" "}
                        {ticket.readers
                            .map(readerId => readers.find(reader => reader.id === readerId)?.name)
                            .join(", ")}
                    </Typography>
                </Box>
                </Box>
        ))}
<Box sx={{ display:"flex", justifyContent:"space-between" }}>
      <TextField
        sx={{ width: "66%" }}
        label={t("newTicket.email")}
        value={email}
        onChange={(e) => setEmail(e.target.value)} // Ažuriraj email kada korisnik unese
      />
      <Button
        sx={{ width: "33%" }}
        variant="contained"
        onClick={handleSendEmail} // Pozivamo handleSendEmail prilikom klika
        disabled={!email} // Onemogućujemo dugme ako email nije unet
      >
        {t("newTicket.send")}
      </Button>
    </Box>
    </Box>
    
)}
            {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
            {succ && <Success message={succ} onClose={() => setSucc('')} />}
        </Container>
    );
};

export default QRGenerateTicket;