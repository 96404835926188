import React, { useState, useEffect } from 'react';
import { getReaders } from '../../api/axios';
import DataTable from '../Table/DataTable';
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';

const ReadersList = () => {
  const { t } = useTranslation('global');

  const [readers, setReaders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReaders = async() =>{
    const response = await getReaders();
    setReaders(response);
    
    setLoading(false);
  };

  useEffect(() => {
    fetchReaders();
  }, []);

  const columns = [
    { Header: t("list.name"), accessor: 'name' },
    { Header: t("list.serialNumber"), accessor: 'serial_num' },
    { Header: t("list.active"), accessor: 'active' },
    { Header: t("list.anti_passback"), accessor: 'anti_passback' },
    { Header: t("list.access_type"), accessor: 'access_type' },
    { Header: t("list.cooldown"), accessor: 'cooldown' },
    { Header: t("list.cooldown_group"), accessor: 'cooldown_group' }
  ];

  if (loading) {
    return <p>{t("list.loading")}</p>;
  }

  return (
    <Container sx={{ mt: 5 }} fullWidth>
      <DataTable initialData={readers} columns={columns} entityName="readers" h2={t("list.readers")} />
    </Container>
  );
};

export default ReadersList;
