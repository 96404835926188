import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Drawer, Box } from '@mui/material';
import { hasPermission } from '../../context/AuthFunc';
import { baseURL, PERMISSIONS } from '../../constants/const';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import DownloadApp from '../DownloadAPP/DownloadAPP';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Menu as MenuIcon } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import VideocamIcon from '@mui/icons-material/Videocam';
import UserManuals from '../UserManuals/UserManuals';
import SettingsMenu from '../SettingsMenu/SettingsMenu'
import { useLocation } from 'react-router-dom';
import { getMode } from '../../api/axios.js'


const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const { t } = useTranslation('global');
  const [adminAnchorEl, setAdminAnchorEl] = useState(null);
  const [salesAnchorEl, setSalesAnchorEl] = useState(null);
  const [newTicketAnchorEl, setNewTicketAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("0");
  const location = useLocation();

  useEffect(()=>{
    const fetchMode = async () => {
      const res = await getMode();
      setMode(res);
    }
    fetchMode();
  },[])

  const handleOpenCamera = () =>{
    window.location.href = "/hik";
  }
  const handleMenuOpen = (anchorElSetter) => (event) => {
    anchorElSetter(event.currentTarget);
  };

  const handleMenuClose = (anchorElSetter) => () => {
    anchorElSetter(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };



  const renderButtonWithMenu = (menuState, setMenuState, menuItems, label) => {

    const filteredItems = menuItems.filter(item => hasPermission(item.permission));
    if (filteredItems.length !== 0) {
      const handleMouseOver = (event) => {
        setMenuState(event.currentTarget); // Postavlja dugme kao anchor element za meni
      };

      const handleMouseLeave = (event) => {
        const relatedTarget = event.relatedTarget;

        if (
          !relatedTarget || // Proverava da li je relatedTarget null ili undefined
          !(relatedTarget instanceof Node) || // Proverava da li je validan DOM Node
          !event.currentTarget.contains(relatedTarget) // Proverava da li currentTarget sadrži relatedTarget
        ) {
          setMenuState(null); // Zatvara meni ako uslovi nisu zadovoljeni
        }
      };

      return (
        <Box
          sx={{ display: 'flex' }}
          onMouseLeave={handleMouseLeave} // Proverava da li je miš napustio i dugme i meni
        >
          <Button
            color="inherit"
            sx={{ textTransform: 'none', fontSize: '20px' }}
            onMouseOver={handleMouseOver} // Otvori meni kada se pređe mišem
            fullWidth
          >
            {label}
          </Button>

          <Menu
            anchorEl={menuState}
            open={Boolean(menuState)}
            onClose={() => setMenuState(null)} // Osigurava da se meni zatvori kada se fokus izgubi
            MenuListProps={{
              onMouseLeave: handleMouseLeave, // Proverava da li je miš napustio meni
            }}
          >
            {menuItems.map((item, index) => (
              
              <div>
                {hasPermission(item.permission) && (
                <MenuItem
                  key={index}
                  onClick={() => setMenuState(null)} // Zatvara meni kada se izabere opcija
                  component="a"
                  href={item.href}
                > 
                  {item.label}
                  
                </MenuItem>
                )}
              </div>
            ))}
          </Menu>
        </Box>
      );
    }
    return null;
  };


  return (
    <>
      <AppBar position="fixed" color="primary">
        <Toolbar sx={{ height: "64px" }}>
          {/* Glavni Box sa dugmadima */}
          <Box sx={{ display: 'flex' }}>
            {/* Desktop Navbar */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, height: '100%' }}>

              {/* Home */}
              <Button color="inherit" href="/" sx={{ textTransform: 'none', fontSize: '20px' }}>
                {t('navbar.home')}
              </Button>

              {/* Admin */}
              {renderButtonWithMenu(
                adminAnchorEl,
                setAdminAnchorEl,
                [
                  { label: t('navbar.ticketDesign'), href: '/ticket-design', permission: PERMISSIONS.TICKET_DESIGN},
                  { label: t('navbar.addReader'), href: '/new-reader', permission: PERMISSIONS.CREATE_NEW_READER },
                  { label: t('navbar.edit/deleteReader'), href: '/readers', permission: PERMISSIONS.EDIT_READER },
                  { label: t('navbar.addUser'), href: '/new-user', permission: PERMISSIONS.CREATE_NEW_USER },
                  { label: t('navbar.rights'), href: '/access', permission: PERMISSIONS.VIEW_ACCESS_RIGHTS },
                  ...(mode === "1" ? [
                    { label: t('navbar.templateGroups'), href: '/template-groups', permission: PERMISSIONS.VIEW_TEMPLATE_GROUPS },
                    { label: t('navbar.templateGroupEvents'), href: '/template-group-events', permission: PERMISSIONS.VIEW_TEMPLATE_GROUP_EVENTS }
                  ] : []),
                ],
                t('navbar.admin')
              )}

              {/* Sales */}
              {renderButtonWithMenu(
                salesAnchorEl,
                setSalesAnchorEl,
                [
                  { label: t('navbar.newPlasticTicket'), href: '/new-ticket', permission: PERMISSIONS.CREATE_NEW_TICKET},
                  { label: t('navbar.newPaperTicket'), href: '/new-paper-ticket', permission: PERMISSIONS.CREATE_NEW_TICKET },
                  { label: t('navbar.addItem'), href: '/new-item', permission: PERMISSIONS.CREATE_NEW_ITEM},
                  { label: t('navbar.edit/deleteItem'), href: '/price-list', permission: PERMISSIONS.VIEW_PRICELIST },
                  { label: t('navbar.addKind'), href: '/add-kind', permission: PERMISSIONS.CREATE_NEW_KIND },
                  { label: t('navbar.addCategory'), href: '/add-category', permission: PERMISSIONS.CREATE_NEW_CATEGORY },
                  { label: t('navbar.monitor'), href:'/monitoring', permission: PERMISSIONS.VIEW_MONITORING},
                  { label: t('navbar.purchasing'), href: '/purchasing', permission: PERMISSIONS.VIEW_PURCHASING}
                ],
                t('navbar.sales')
              )}

              
            </Box>

            {/* Mobile Navbar */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
              <IconButton onClick={handleDrawerToggle} color="inherit">
                <MenuIcon />
              </IconButton>
            </Box>

            {/* Drawer for mobile */}
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
              sx={{
                '& .MuiDrawer-paper': {
                  width: 250,
                  padding: '20px',
                },
              }}
            >
              {/* Home */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button color="inherit" href="/" sx={{ textTransform: 'none', fontSize: '20px' }}>
                  {t('navbar.home')}
                </Button>

                {/* Admin */}
                {renderButtonWithMenu(
                adminAnchorEl,
                setAdminAnchorEl,
                [
                  { label: t('navbar.ticketDesign'), href: '/ticket-design', permission: PERMISSIONS.TICKET_DESIGN},
                  { label: t('navbar.addReader'), href: '/new-reader', permission: PERMISSIONS.CREATE_NEW_READER },
                  { label: t('navbar.edit/deleteReader'), href: '/readers', permission: PERMISSIONS.EDIT_READER },
                  { label: t('navbar.addUser'), href: '/new-user', permission: PERMISSIONS.CREATE_NEW_USER },
                  { label: t('navbar.rights'), href: '/access', permission: PERMISSIONS.VIEW_ACCESS_RIGHTS },
                  ...(mode === "1" ? [
                    { label: t('navbar.templateGroups'), href: '/template-groups', permission: PERMISSIONS.VIEW_TEMPLATE_GROUPS },
                    { label: t('navbar.templateGroupEvents'), href: '/template-group-events', permission: PERMISSIONS.VIEW_TEMPLATE_GROUP_EVENTS }
                  ] : []),
                ],
                t('navbar.admin')
              )}

              {/* Sales */}
              {renderButtonWithMenu(
                salesAnchorEl,
                setSalesAnchorEl,
                [
                  { label: t('navbar.newPlasticTicket'), href: '/new-ticket', permission: PERMISSIONS.CREATE_NEW_TICKET},
                  { label: t('navbar.newPaperTicket'), href: '/new-paper-ticket', permission: PERMISSIONS.CREATE_NEW_TICKET },
                  { label: t('navbar.addItem'), href: '/new-item', permission: PERMISSIONS.CREATE_NEW_ITEM},
                  { label: t('navbar.edit/deleteItem'), href: '/price-list', permission: PERMISSIONS.VIEW_PRICELIST },
                  { label: t('navbar.addKind'), href: '/add-kind', permission: PERMISSIONS.CREATE_NEW_KIND },
                  { label: t('navbar.addCategory'), href: '/add-category', permission: PERMISSIONS.CREATE_NEW_CATEGORY },
                  { label: t('navbar.monitor'), href:'/monitoring', permission: PERMISSIONS.VIEW_MONITORING},
                  { label: t('navbar.purchasing'), href: '/purchasing', permission: PERMISSIONS.VIEW_PURCHASING}
                ],
                t('navbar.sales')
              )}
              </Box>
            </Drawer>
          </Box>

          {/* Drugi Box sa ikonama */}
          <Box
            sx={{
              display: "flex",
              position: "fixed", // Koristimo 'fixed' da bude stalno u gornjem desnom kutu
              zIndex: 1000,
              right: 0,
              flexDirection: "row", // Dugmadi vertikalno
              gap: 1, // Razmak između dugmadi
              padding: 1.5, // Dodajte padding ako je potrebno
              justifySelf: "flex-end"
            }}
          >
              <IconButton sx={{ color: "white" }} onClick={() => handleOpenCamera()}>
                  <VideocamIcon />
              </IconButton>
              <IconButton sx={{ color: "white" }} onClick={() => setOpen(true)}>
                  <HelpIcon />
              </IconButton>
              <UserManuals open={open} handleClose={() => setOpen(false)} page={location.pathname} />
            <LanguageSwitch />
            <SettingsMenu isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
          </Box>

        </Toolbar>
      </AppBar>

      {/* Spacer to prevent content overlap */}
      <Toolbar />
    </>
  );
};

export default Navbar;
